import { helper } from '@ember/component/helper';

export function clampText([value, ...rest]) {
  if(value){
    let out = value.substring(0, 30);
    if(value.length > 30){
      out += "... ";
    }
    return out;
  }
  return value;
}

export default helper(clampText);
