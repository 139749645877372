import Controller from '@ember/controller';
import {
  task,
  all
} from 'ember-concurrency';
import {
  computed
} from '@ember/object';

export default Controller.extend({
  relevantTermAnmeldung: computed('model.termAnmeldung.value.@each.anmeldungen', 'model.events.value.[]', function(){
    const events = this.get('model.events.value')
    if(events){
      const tas = this.get('model.termAnmeldung.value')
      if(tas){
        const eventIds = events.mapBy('id')
        return tas.filter(ta => {
          const an = ta.get('anmeldungen').filter(a => eventIds.includes(a.belongsTo('event').id()))
          return an.get('length') > 1 && an.any(a => a.get('status') === 'included')
        })
      }
    }
    return []
  }),
  orderedRelevantTermAnmeldung: computed('relevantTermAnmeldung.@each.optimizerTempGroup', function(){
    const options = ["(1)","1","(2)","2","(3)","3","(4)","4","(5)","5", "+","",null,"-" ]
    return this.get('relevantTermAnmeldung').toArray().sort((a, b) => {
      return options.indexOf(a.get('optimizerTempGroup'))-options.indexOf(b.get('optimizerTempGroup'))
    })
  }),
  allAnmeldungenOfRelevantTermAnmeldung: computed('relevantTermAnmeldung.@each.anmeldungen', function(){
    const relevantTermAnmeldung = this.get('relevantTermAnmeldung')
    const anmel = relevantTermAnmeldung.map(ta => ta.get('anmeldungen'));
    return anmel.reduce((summation, current) => {
      if(current){
        return summation.pushObjects(current.toArray());
      }
      return summation
    }, [])
  }),
  relevantAnmeldungen: computed('allAnmeldungenOfRelevantTermAnmeldung.@each.eventID', 'model.events.value.@each.id', function(){
    const events = this.get('model.events.value')
    if(events){
      const anmeldungen = this.get('allAnmeldungenOfRelevantTermAnmeldung')
      return anmeldungen.filter(a => {
        return events.any(e => a.get('eventID') === e.get('id'))
      })
    }
  }),
  globalResetAll: task(function* () {
    const term = yield this.get('model.term')
    const termAnmeldungen = yield term.get('termAnmeldungen')
    termAnmeldungen.forEach(a => a.set('optimizerTempGroup', ''))
    const anmeldungen = yield term.get('anmeldungen')
    anmeldungen.forEach(a => a.set('_optimizerTempStatus', null))
  }),
  globalResetStatus: task(function* () {
    const term = yield this.get('model.term')
    const anmeldungen = yield term.get('anmeldungen')
    anmeldungen.forEach(a => a.set('_optimizerTempStatus', null))
  }),
  localResetAll: task(function* () {
    const termAnmeldungen = this.get('relevantTermAnmeldung')
    termAnmeldungen.forEach(a => a.set('optimizerTempGroup', ''))
    const anmeldungen = this.get('relevantAnmeldungen')
    anmeldungen.forEach(a => a.set('_optimizerTempStatus', null))
  }),
  localResetStatus: task(function* () {
    const anmeldungen = this.get('relevantAnmeldungen')
    anmeldungen.forEach(a => a.set('_optimizerTempStatus', null))
  }),
  applyChanges: task(function* () {
    const anmeldungen = this.get('relevantAnmeldungen')
    anmeldungen.forEach(a => {
      if(a.get('tempStatusPlus')){
        a.set('status', 'included')
        a.set('_optimizerTempStatus', null)
      }else if(a.get('tempStatusMinus')){
        a.set('status', 'toBeDetermind')
        a.set('_optimizerTempStatus', null)
      }
    })
  }),

});
