import Service from '@ember/service';
import {
    inject
  } from '@ember/service';
  import {
    task
  } from 'ember-concurrency';

  import {
    computed
  } from '@ember/object';

  import moment from 'moment';
  
  import firebase from 'firebase';

export default Service.extend({
    firebase: inject(),

    changeRegistrationForCours: task(function* (anmeldeid, eventid, status, anmerkung) {
        return yield firebase.functions().httpsCallable('httpsChangeRegistrationOnCall')({ anmeldeid, eventid, status, anmerkung, kurs: true })
    }),
    closeEvent: task(function* (eventid) {
      return yield firebase.functions().httpsCallable('httpsCloseEventOnCall')({ eventid })
    }),
    updateEvent: task(function* (eventid, oeffentlich, vfarbe, hfarbe) {
      return yield firebase.functions().httpsCallable('httpsUpdateEventOnCall')({ eventid, oeffentlich, vfarbe, hfarbe })
    }),
    loadTermsAndTeilnehmer: task(function* () {
        return yield firebase.functions().httpsCallable('httpsLoadTermsAndTeilnehmerOnCall')()
    }),
    loadTerm: task(function* (termid) {
        return yield firebase.functions().httpsCallable('httpsLoadTermOnCall')({termid})
    }),
    login: task(function* (user, password) {
      return yield firebase.functions().httpsCallable('httpsLoginOnCall')({user, password})
    }),

    convertToMemberWithTermanmeldungen: task(function* (termAnmeldungen, date) {
      const ids = yield termAnmeldungen.map(i => i.get('teilnehmer.id'))
      yield this.get('convertToMember').perform(ids, date)
    }),

    convertToMember: task(function* (ids, date) {
      if(!date){
        date = new Date()
      }
      const dstring = moment(date).format('DD.MM.YYYY')
      return yield firebase.functions().httpsCallable('httpsConvertToMemberOnCall')({ids, date: dstring})
    }),
    isRunning: computed('changeRegistrationForCours.isRunning', 'loadTermsAndTeilnehmer.isRunning', 'loadTerm.isRunning', 'closeEvent.isRunning', 'updateEvent.isRunning', 'convertToMember.isRunning', function(){
        return this.get('changeRegistrationForCours.isRunning') || this.get('loadTermsAndTeilnehmer.isRunning') || this.get('loadTerm.isRunning') || this.get('closeEvent.isRunning') || this.get('updateEvent.isRunning') || this.get('convertToMember.isRunning')
    })
});
