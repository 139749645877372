import Service from '@ember/service';
import {
  computed,
  observer
} from '@ember/object';
import {
  alias
} from '@ember/object/computed';
import {
  task,
  timeout
} from 'ember-concurrency';
import {
  on
} from '@ember/object/evented';

export default Service.extend({
  tasks: [],
  models: [],
  prolongedDuration: 600,
  isSaving: computed('tasks.@each.isRunning', 'models.@each.isSaving', function () {
    return (this.get('tasks').isAny('isRunning') || this.get('models').isAny('isSaving'));
  }),
  prolongedIsSaving: alias('_prolongedIsSaving.isRunning'),
  _isSavingChanged: on('init', observer('isSaving', function () {
    if(this.get('isSaving')){
      this.get('_prolongedIsSaving').perform();
    }
  })),
  _prolongedIsSaving: task(function* () {
    while (this.get('isSaving')) {
      yield timeout(this.get('prolongedDuration'));
    }
  }).drop(),
  addTask(task) {
    this.get('tasks').pushObject(task);
  },
  removeTask(task) {
    this.get('tasks').removeObject(task);
  },
  addModel(model) {
    this.get('models').pushObject(model);
  },
  removeModel(model) {
    this.get('models').removeObject(model);
  },
});
