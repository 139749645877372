import ToriiFirebaseAdapter from 'emberfire/torii-adapters/firebase';
import {
  inject as service
} from '@ember/service';
import { resolve } from 'rsvp';
import firebase from 'firebase';

export default ToriiFirebaseAdapter.extend({
  firebase: service(),
  store: service(),
  open(authentication) {
    return this._findOrCreateUser(authentication.uid, authentication)
      .then(user => resolve({
        provider: this.extractProviderId_(authentication),
        uid: authentication.uid,
        currentUser: user
      }));
  },

  /**
   * Close existing authenticated session
   *
   * @return {Promise}
   */
  close(options) {
    if (!options || !options.temp) {
      return this.get('firebase').auth().signOut();
    }
  },

  _findOrCreateUser(uid) {
    const [id] = uid.split('@')
    let store = this.get('store');
    return store.findRecord('teilnehmer', id)
  },
});
