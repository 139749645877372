import DS from 'ember-data';
import AutoSave from '../mixins/auto-save-model';
import IndicateSaveing from '../mixins/indicate-saving-model';
import {
  computed
} from '@ember/object';
import { alias } from '@ember/object/computed';

export default DS.Model.extend(AutoSave, IndicateSaveing, {
  name: DS.attr('string'),
  anmeldung: DS.belongsTo('anmeldung', { async: true, inverse: 'todos' }),
  event: DS.belongsTo('event', { async: true, inverse: 'todos' }),
  teilnehmer: DS.belongsTo('term-anmeldung', { async: true, inverse: 'todos' }),
  term: DS.belongsTo('term', { async: true, inverse: 'todos' }),
  status: DS.attr('string'),
  sortValue: computed('status', function(){
    switch(this.get('status')){
      case "Todo":
        return 0
      case "Waiting":
        return 1
      case "Done":
       return 2
    }
    return 3
  }),
  cEvent: computed('event', 'anmeldung.event', function(){
    if(this.get('event.id')) return this.get('event')
    if(this.get('anmeldung.event')) return this.get('anmeldung.event')
    return false
  }),
  cTeilnehmer: computed('teilnehmer', 'anmeldung.termAnmeldung', function(){
    if(this.get('teilnehmer.id')) return this.get('teilnehmer')
    if(this.get('anmeldung.termAnmeldung')) return this.get('anmeldung.termAnmeldung')
    return false
  })
});
