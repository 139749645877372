import {
  inject
} from '@ember/service';
import Component from '@ember/component';
import {
  task
} from 'ember-concurrency';

export default Component.extend({
  auth: inject(),
  session: inject(),
  login: task(function* () {
    let login = this.get('auth.login').perform();
    yield login;
    if(this.get('onAfterLogin') && login.get('state') == 'finished'){
      this.get('onAfterLogin')();
    }
  }).drop(),
  logout: task(function* () {
    yield this.get('auth.logout').perform();
    if(this.get('onAfterLogout')){
      this.get('onAfterLogout')();
    }
  }).drop(),
  closeDialog: task(function* () {
    yield this.trigger('cancelSignIn', 'cancelSignIn');
  })
});
