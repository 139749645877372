import Mixin from '@ember/object/mixin';
import {
    computed,
    observer
  } from '@ember/object';
  import {
    on
  } from '@ember/object/evented';
  import {
    task,
    timeout
  } from 'ember-concurrency';

export default Mixin.create({
    _autoSaveDisableCount: 0,
    timeToWaitBeforeAutoSave: 300,
    isAutoSaving: computed('_autoSaveDisableCount', function(){
        return this.get('_autoSaveDisableCount') === 0
    }),
    _autoSaveChanged: on('init', observer('isAutoSaving', 'hasDirtyAttributes', 'dirtyType', function () {
        if(this._shouldItAutoSave()){
            this.get('_autoSave').perform();
        }
    })),
    _autoSave: task(function* () {
        yield timeout(this.get('timeToWaitBeforeAutoSave'));
        if(this._shouldItAutoSave()){
            yield this.save();
        }
    }).keepLatest(),
    _shouldItAutoSave(){
        return this.get('isAutoSaving') && this.get('hasDirtyAttributes') && this.get('dirtyType') === 'updated';
    }
});
