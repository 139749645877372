import Component from '@ember/component';
import {
    computed
  } from '@ember/object';

export default Component.extend({
    isOpen: false,
    actions: {
        open() {
            this.set("isOpen", true)
        },
        close() {
            this.set("isOpen", false)
        }
    }
});