import Route from '../../-base';
import {
  task,
  all
} from 'ember-concurrency';

export default Route.extend({
    model(params) {
      const parentModel = this.modelFor('term.term');
      const event = this.get('findEvent').perform(params.event_id);
      const anmeldungen = this.get('findAnmeldungen').perform(event);
      const termAnmeldung = this.get('findTermAnmeldung').perform(anmeldungen);
      const teilnehmer = this.get('findTeilnehmer').perform(termAnmeldung);
      return {
        term: parentModel.term,
        activityLevel: parentModel.activityLevel,
        event: event,
        anmeldungen: anmeldungen,
        termAnmeldung: termAnmeldung,
        teilnehmer: teilnehmer,
        allLoadedForStatus: parentModel.allLoadedForStatus,
        vorlagen: parentModel.vorlagen
      };
    },
    findEvent: task(function* (event_id) {
        return yield this.get('store').findRecord('event', event_id);
    }),
    findAnmeldungen: task(function* (event) {
        event = yield event;
        return yield event.get('anmeldungen');
    }),
    findTermAnmeldung: task(function* (anmeldungen) {
        anmeldungen = yield anmeldungen;
        return yield all(anmeldungen.map(item => item.get('termAnmeldung')));
    }),
    findTeilnehmer: task(function* (termAnmeldung) {
      termAnmeldung = yield termAnmeldung;
      yield all(termAnmeldung.map(item => item.get('teilnehmer')));
  }),
})

