import Controller from '@ember/controller';
import {
  computed
} from '@ember/object';
import {
  inject
} from '@ember/service';

export default Controller.extend({
  automatedAllocation: inject(),
  otherTermAnmeldungen: computed('model.term.value', 'model.termAnmeldung.value.teilnehmer.termAnmeldungen.@each.{anmeldungen,term}', function(){
    const term = this.get('model.term.value')
    return this.get('model.termAnmeldung.value.teilnehmer.termAnmeldungen').filter(a => a.get('term.id') !== term.get('id'))
  }),
  otherAnmeldungen: computed('otherTermAnmeldungen.@each.anmeldungen', function(){
    return this.get('otherTermAnmeldungen').reduce((previousValue, item) => previousValue.pushObjects(item.get('anmeldungen').map(item => item)), []);
  }),
});
