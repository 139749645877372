import Mixin from '@ember/object/mixin';
import {
    task,
    allSettled
} from 'ember-concurrency';

export default Mixin.create({
    afterModel(model, transition) {
        this.get('handleErrors').perform(model, transition);
    },
    handleErrors: task(function* (model, transition) {
        let instances = [];
        if(model && model.task && Number.isInteger(model.task.concurrency)){
            instances.pushObject(model);
        }else if(typeof model === 'object' && model !== null){
            for (var key in model) {
                if (model.hasOwnProperty(key) && model[key] && model[key].task && Number.isInteger(model[key].task.concurrency)) {
                    instances.pushObject(model[key]);
                }
            }
        }
        yield allSettled(instances);
        let errorInstance = instances.findBy('error');
        if (errorInstance) {
            try{
                this.send('error', errorInstance.get('error'), transition);
            }catch(e){
                transition.send('error', errorInstance.get('error'), transition);
            }
            
        }
    }).cancelOn('deactivate'),
});
