import Component from '@ember/component';
import { task } from 'ember-concurrency';
import {
    inject
  } from '@ember/service';

export default Component.extend({
    store: inject(),
    name: '',
    onKeyDown: task(function * (event) {
        if(event.key === "Enter"){
            yield this.get("save").perform()
        }
    }),
    save: task(function * () {
        try{
            const record = this.get('store').createRecord('todo', {name: this.get('name'), status: "Todo"})
            if(this.get('term')){
                record.set('term', this.get('term'))
            }
            if(this.get('event')){
                record.set('event', this.get('event'))
            }
            if(this.get('anmeldung')){
                record.set('anmeldung', this.get('anmeldung'))
            }
            if(this.get('teilnehmer')){
                record.set('teilnehmer', this.get('teilnehmer'))
            }
            yield record.save()
            if(this.get('term')){
                yield this.get('term').save()
            }
            if(this.get('event')){
                yield this.get('event').save()
            }
            if(this.get('anmeldung')){
                yield this.get('anmeldung').save()
            }
            if(this.get('teilnehmer')){
                yield this.get('teilnehmer').save()
            }
            this.set('name', '')
            
        }catch(e) {
            console.log(e)
            //TODO
        }
    }).drop(),
});