import Controller from '@ember/controller';
import {
  task,
  all
} from 'ember-concurrency';

export default Controller.extend({
    queryParams: ['queryAbsagen'],
    searchProperties: ['vorname', 'nachname', 'email', 'absageGrund'],
    autoSetPrio: task(function* () {
      const term = yield this.model.term.value;
      const termAnmeldungen = term.get('termAnmeldungen');
      let rt = termAnmeldungen.map(t => {
        if(t.withoutEvent != t.prioForNextTerm){ //TODO: not if to late oder wish
          t.set('prioForNextTerm', t.withoutEvent);
          return t.teilnehmer;
        }
      });
      rt = yield all(rt);
      yield all(rt.invoke('save'));
    }),
    setPrio: task(function* (termAnmeldung, value) {
      termAnmeldung.set('prioForNextTerm', value);
      const rt = yield termAnmeldung.teilnehmer; //TODO what wenn no teilnehmer?
      yield rt.save();
    }),
    actions:{
      extendedSortKey(key){
        if(key == 'kurs:desc'){
          return ['lengthIncludedAnmeldungen:desc', 'lengthToBeDetermindAnmeldungen:desc', 'vorname', 'nachname'];
        }else if(key == 'kurs:asc'){
          return ['lengthIncludedAnmeldungen:asc', 'lengthToBeDetermindAnmeldungen:asc', 'vorname', 'nachname'];
        }else{
          return [key, 'vorname:asc', 'nachname:asc'];
        }
      }
    }
});
