import Route from '../../../-base';
import {
    task,
  } from 'ember-concurrency';

export default Route.extend({
    model(params) {
        const parentModel = this.modelFor('term.term');
        const step = this.get('findStep').perform(params.step_id);
        return {
          term: parentModel.term,
          step,
          allLoadedForStatus: parentModel.allLoadedForStatus,
        };
      },
      findStep: task(function* (step_id) {
          return yield this.get('store').findRecord('process-step', step_id);
      }),
});
