 import { filter, bool, or } from '@ember/object/computed';

 import Component from '@ember/component';
 import { computed, observer } from '@ember/object';
 import { next } from '@ember/runloop'
 import RippleMixin from 'ember-paper/mixins/ripple-mixin';
 import { ParentMixin } from 'ember-composability-tools';
 import { invokeAction } from 'ember-invoke-action';
 import {htmlSafe} from '@ember/string'
 /**
  * @class PaperItem
  * @extends Ember.Component
  * @uses ParentMixin
  * @uses RippleMixin
  */
 export default Component.extend(RippleMixin, ParentMixin, {
   tagName: 'md-list-item',
 
   // Ripple Overrides
   rippleContainerSelector: '.md-no-style',
   // disable ripple when we have a primary action or when we don't have a proxied component
   noink: computed('hasPrimaryAction', 'hasProxiedComponent', function() {
     return this.get('hasPrimaryAction') || !this.get('hasProxiedComponent');
   }),
 
   dragging: false,
   center: false,
   dimBackground: true,
   outline: false,
   draggIsOver: '',
 
   classNameBindings: [
     'hasProxiedComponent:md-proxy-focus', 'shouldBeClickable:md-clickable',
     'focused:md-focused', 'hasPrimaryAction:_md-button-wrap'
   ],
   attributeBindings: ['role', 'tabindex', 'title', 'style', 'draggable'],
   role: 'listitem',
   tabindex: '-1',
   draggable: false,
 
   proxiedComponents: filter('childComponents', function(c) {
     return !c.get('skipProxy');
   }),
 
   hasProxiedComponent: bool('proxiedComponents.length'),
   shouldBeClickable: or('hasProxiedComponent', 'onClick'),
 
   hasPrimaryAction: or('onClick', 'href'),
 
   noProxy: computed('hasPrimaryAction', 'hasProxiedComponent', function() {
     return !this.get('hasPrimaryAction') && !this.get('hasProxiedComponent');
   }),
 
   secondaryItem: computed('proxiedComponents.[]', function() {
     let proxiedComponents = this.get('proxiedComponents');
     return proxiedComponents.objectAt(0);
   }),
 
   click() {
     this.get('proxiedComponents').forEach((component) => {
       if (component.processProxy && !component.get('disabled') && (component.get('bubbles') | !this.get('hasPrimaryAction'))) {
         component.processProxy();
       }
     });
   },
 
   mouseEnter(e) {
     invokeAction(this, 'onMouseEnter', e);
   },
 
   mouseLeave(e) {
     invokeAction(this, 'onMouseLeave', e);
   },

   mouseDown(e){
    const timeout = setTimeout((that) => {
        that.processMouseDown(e)
    },300, this);
    this.set('downTimeout', timeout)
   },

   processMouseDown(e){
    console.log('go')
    this.set('width', this.get('element').clientWidth)
     const height = this.get('element').clientHeight
     this.set('height', height)
     this.set('offset', e.clientY-this.get('element').getBoundingClientRect().top)
     this.set('dragging', true)
     this.get('parentComponent').startDragging(e, height)
   },

   mouseUp(){
    clearTimeout(this.get('downTimeout'));
    if(this.get('dragging')){
        this.set('dragging', false)
        const position = this.get('parentComponent').stopDragging()
        if(position-1 !== this.get('position')){
            invokeAction(this, 'onRearange', position);
        }
    }
   },

   draggingChange: observer('parentComponent.{y,dragging,dragItemHight}', function() {
     next(this, 'processDraggingChange');
   }),

   processDraggingChange() {
        if(this.get('parentComponent.dragging') && !this.get('dragging')){
            const mouseY = this.get('parentComponent.y')
            let start = this.get('element').getBoundingClientRect().top
            let height = this.get('element').clientHeight
            let middle = start + height/2
            let end = start + height
            if(this.get('draggIsOver') === 'top'){
                start -= this.get('parentComponent.dragItemHight')
            }else if(this.get('draggIsOver') === 'bottom'){
                end += this.get('parentComponent.dragItemHight')
            }
            if(mouseY > start && mouseY <= middle ){
                this.set('draggIsOver', 'top')
                this.get('parentComponent').setDragPlace(this.get('position'))
            }else if(mouseY > middle && mouseY <= end ){
                this.set('draggIsOver', 'bottom')
                this.get('parentComponent').setDragPlace(this.get('position')+1)
            }else{
                this.set('draggIsOver', false)
            }
       }else{
           this.set('draggIsOver', false)
       }
   },

   style: computed('dragging', 'width', 'height', 'parentComponent.{y,dragItemHight}', 'draggIsOver', function(){
       if(this.get('dragging')){
           const width = this.get('width')
           const top = this.get('parentComponent.y')-this.get('offset')
           return htmlSafe(`position:fixed; z-index:30000; width:${width}px; top: ${top}px; filter: drop-shadow(8px 8px 5px #ccc);` )
       }else{
           const dragItemHight = this.get('parentComponent.dragItemHight')
           if(this.get('draggIsOver') === 'top'){
             return htmlSafe(`margin-top:${dragItemHight}px`)
           }else if(this.get('draggIsOver') === 'bottom'){
             return htmlSafe(`margin-bottom:${dragItemHight}px`)
           }else{
             return htmlSafe('')
           }
       }
   })
 });