import Controller from '@ember/controller';
import {
  computed
} from '@ember/object';

export default Controller.extend({
  termTrainer: computed('model.trainer.value.@each.{vorname,nachname}', 'model.events.value.@each.trainer', 'model.term.value.notCanceldEvents.@each.{trainer,includedAnmeldungenProvLength}', function (){
    return this.get('model.trainer.value').map(t => {
      const es = this.get('model.events.value').filter(e => e.get('trainer').includes(t))
      const eNotCanceld = this.get('model.term.value.notCanceldEvents').filter(e => e.get('trainer').includes(t))
      const eWithProv = eNotCanceld.filter(e => e.get('includedAnmeldungenProvLength') > 0)
      return {
        vorname: t.get('vorname'),
        nachname: t.get('nachname'),
        events: es,
        copyTextNiveauEinschaetzungen: eNotCanceld.map(e2 => 'https://www.vereinonline.org/BeachL_eV/?action=events_anmeldungen&id='+e2.get('id')+'&dialog=2').join('\r\n')+'',
        hasProvZusagen: eWithProv.get('length') > 0,
        copyTextProvZusagen: eWithProv.map(e2 => e2.get('titel')+'\r\n'+e2.get('includedAnmeldungenProv').map(a => '* '+a.get('vorname')+' '+a.get('nachname')).join('\r\n')).join('\r\n')+'',
      }
    })
  })
});
