import Controller from '@ember/controller';
import {
  computed
} from '@ember/object';

export default Controller.extend({
  filterTodos: '',
  queryParams: ['filterTodos'],
  filteredTodos: computed('filterTodos', 'model.term.value.todosAlle.@each.status', function(){
    if(!this.get('model.term.value.todosAlle')) return []
    if(this.get('filterTodos') === 'all'){
      return this.get('model.term.value.todosAlle')
    }else if(this.get('filterTodos') === 'Done'){
      return this.get('model.term.value.todosAlle').filter(i => i.status === 'Done')
    }else if(this.get('filterTodos') === 'Waiting'){
      return this.get('model.term.value.todosAlle').filter(i => i.status === 'Waiting')
    }else if(this.get('filterTodos') === 'Todo'){
      return this.get('model.term.value.todosAlle').filter(i => i.status === 'Todo')
    }
    return this.get('model.term.value.todosAlle').filter(i => i.status !== 'Done')
  }),
});
