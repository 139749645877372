import Component from '@ember/component';
import { computed }  from '@ember/object';
import { mapBy } from '@ember/object/computed';

export default Component.extend({
  termAnmeldungen: mapBy('anmeldungen', 'termAnmeldung'),
  optimizerIDString: computed('termAnmeldungen.@each.{eventIDs,includedAnmeldungen,anmeldungen}', function(){
    const value = this.get('termAnmeldungen')
    if(value){
      if(value.get('length') === 1){
        return value.get('firstObject.eventIDs').toArray().join('.')
      }else if(value.get('length') > 1){
        const events = {}
        const eventsA = []
        value.forEach(ia => {
          ia.get('anmeldungen').forEach(a => {
            const id = a.belongsTo('event').id()
            if(!events[id]){
              events[id] = {anyIncluded: false, count:0, id:id}
              eventsA.pushObject (events[id])
            }
            events[id].count++
            if(a.get('status') === 'included'){
              events[id].anyIncluded = true
            }
          })
        })
        return eventsA.filter(i => i.count > 1 && i.anyIncluded).mapBy('id').toArray().join('.')
      }
    }
    return '';
  }),
});
