import Mixin from '@ember/object/mixin';
import {
    task
} from 'ember-concurrency';
import {
    inject
  } from '@ember/service';

export default Mixin.create({
    auth: inject(),
    error: inject(),
    unauthorized: task(function* () {
        yield this.get('auth.unauthorized').perform(this);
    }).cancelOn('deactivate').drop(),
    errorOccured: task(function* (error) {
        yield this.get('error.errorOccured').perform(error);
    }).cancelOn('deactivate').drop(),
    actions: {
        error(error) {
            if (error.message.startsWith('permission_denied') || error.message.startsWith('unauthenticated')) {
                //this.get('unauthorized').perform();
            } else {
                this.get('errorOccured').perform(error);
                return true;
            }
        }
    }
   
});
