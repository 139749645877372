import Component from '@ember/component';
import {
  task
} from 'ember-concurrency';
import {
  observer
} from '@ember/object';
import {
  computed
} from '@ember/object';

export default Component.extend({
  selected: false,
  init() {
    this._super(...arguments);
    this.set('selected', []);
  },
  didUpdateAttrs() {
    this._super(...arguments);
    this.set('selected', []);
  },
  toggleSelection: task(function* (object) {
    object = yield object;
    const selected = this.get('selected');
    if(selected.includes(object)){
      selected.removeObject(object);
    }else{
      if(!this.get('hasToBeWithIn') || this.get('hasToBeWithIn').includes(object)){
        selected.pushObject(object);
      }
    }
  }),
  clear: task(function* () {
    yield this.set('selected', []);
  }),
  toggleSelectAll: task(function* () {
    if(this.get('allSelected')){
      yield this.set('selected', []);
    }else{
      yield this.set('selected', this.get('hasToBeWithIn').map(e => e));
    }
  }),
  onHasToBeWithInChange: observer('hasToBeWithIn.[]', function(){
    const hasToBeWithIn = this.get('hasToBeWithIn');
    if(hasToBeWithIn){
      const selected = this.get('selected');
      selected.forEach(object => {
        if(!hasToBeWithIn.includes(object)){
          selected.removeObject(object);
        }
      });
    }
  }),
  somethingSelected: computed('selected.[]', function(){
    return this.get('selected.length') > 0
  }),
  allSelected: computed('selected.length', 'hasToBeWithIn.length', function(){
    return this.get('selected.length') === this.get('hasToBeWithIn.length')
  }),
});
