import Controller from '@ember/controller';
import {
  task,
  all
} from 'ember-concurrency';
import {
  inject
} from '@ember/service';
import {
  computed
} from '@ember/object';


export default Controller.extend({
  store: inject(),
  termsWithSteps: computed('model.terms.value.@each.processSteps', 'model.term.value', function(){
    if(!this.get('model.terms.value') || !this.get('model.term.value')) return 0
    const cterm = this.get('model.term.value')
    return this.get('model.terms.value').filter(t => {
      if(cterm.get('id') === t.get('id')) return false
      return t.hasMany('processSteps').ids().length > 0
    })
  }),
  addProcessStep: task(function* () {
    const term = yield this.get('model.term')
    yield term.get('processSteps')
    const position = term.get('processStepsHighestPosition')+100
    const record = this.get('store').createRecord('process-step', {term, position})
    yield record.save()
    yield term.save()
    this.transitionToRoute('term.term.process-steps.step', term.get('id'), record.get('id'))
  }),
  import: task(function* (sourceTerm) {
    const term = yield this.get('model.term')
    sourceTerm = yield sourceTerm
    const steps = yield sourceTerm.get('processSteps')
    const store = this.get('store')
    const diffVon = term.get('von') && sourceTerm.get('von') ? term.get('von') - sourceTerm.get('von') : false
    const diffBis = term.get('bis') && sourceTerm.get('bis') ? term.get('bis') - sourceTerm.get('bis') : false
    yield all(steps.map(s => {
      const r = {
        term,
        name: s.name,
        text: s.text,
        textSnippet: s.textSnippet,
        type: s.type,
        datesRelativeTo: s.datesRelativeTo,
        url: s.url,
        instructionUrl: s.instructionUrl,
        position: s.position
      }
      const diff = s.datesRelativeTo === 'end' ? diffBis : diffVon
      if(diff !== false){
        if(s.start.valueOf() !== 0) r['start'] = new Date(s.start.valueOf() + diff)
        if(s.end.valueOf() !== 0) r['end'] = new Date(s.end.valueOf() + diff)
        if(s.reminder.valueOf() !== 0) r['reminder'] = new Date(s.reminder.valueOf() + diff)
      }
      return store.createRecord('process-step', r).save()
    }))
    yield term.save()
  }),
});
