import { A as emberA, isArray } from '@ember/array';
import { compare } from '@ember/utils';

export function sortArray(items, sortDefinition) {
    if (typeof sortDefinition === 'function') {
      return customSort(items, sortDefinition);
    } else {
      return propertySort(items, sortDefinition);
    }
  }
  
  function customSort(items, comparator) {
      return items.slice().sort((x, y) => comparator.call(this, x, y));
  }
  
  // This one needs to dynamically set up and tear down observers on the itemsKey
  // depending on the sortProperties
  function propertySort(items, sortProperties) {
      let normalizedSortProperties = normalizeSortProperties(sortProperties);
      if (!isArray(items)) {
        return emberA();
      }
  
      if (normalizedSortProperties.length === 0) {
        return emberA(items.slice());
      } else {
        return sortByNormalizedSortProperties(items, normalizedSortProperties);
      }
  }
  
  function normalizeSortProperties(sortProperties) {
    return sortProperties.map((p) => {
      let [prop, direction] = p.split(':');
      direction = direction || 'asc';
  
      return [prop, direction];
    });
  }
  
  function sortByNormalizedSortProperties(items, normalizedSortProperties) {
    return emberA(
      items.slice().sort((itemA, itemB) => {
        for (let i = 0; i < normalizedSortProperties.length; i++) {
          let [prop, direction] = normalizedSortProperties[i];
          let result = compare(itemA.get(prop), itemB.get(prop));
          if (result !== 0) {
            return direction === 'desc' ? -1 * result : result;
          }
        }
        return 0;
      })
    );
  }