import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';

export default Controller.extend({
  vereinonlineImporter: service(),
  kursFileSelected: task(function* (files) {
    let data = yield this.get('vereinonlineImporter.parseEvents').perform(files[0]);
    const events = yield this.get('model.events');
    const eventIDs = events.mapBy('id');
    this.get('vereinonlineImporter.filterEvents').perform(data, "existing", eventIDs);
    this.set('kursData', data);
    this.set('niveauData', null);
  }),
  filter: task(function* (filter) {
    const events = yield this.get('model.events');
    const eventIDs = events.mapBy('id');
    yield this.get('vereinonlineImporter.filterEvents').perform(this.kursData, filter, eventIDs);
  }),
  syncTerm: task(function* () {
    let term = yield this.get('model.term');
    let data = this.get('kursData').filterBy('selected');
    yield this.get('vereinonlineImporter.importEvents').perform(term, data, this.get('importStatus'));
    this.set('kursData', null);
  }),
  syncNiveaus: task(function* (files) {
    yield this.get('vereinonlineImporter.importNiveaus').perform(files[0]);
  }), 
  setLastTerm: task(function* (lastTerm) {
    lastTerm = yield lastTerm;
    const term = yield this.model.term;
    term.set('lastTerm', lastTerm);
    yield term.save();
  }),
});
