import Component from '@ember/component';
import { next } from '@ember/runloop';
import { computed }  from '@ember/object';

export default Component.extend({
  classNames: ["layout-row"],
  _aktive: false,
  aktive: computed('text', 'aktive', function(){
    if(!this.get('text') && !this.get('_aktive')){
      return false;
    }
    return true;
  }),
  actions:{
    blurReceived(){
      if(!this.get('text')){
        this.set('aktive', false);
      }
    },
    clear(){
      this.set('text', "");
      this.$('input')[0].focus();
    },
    search(){
      this.set('aktive', true);
      next(this, function() {
        this.$('input')[0].focus();
      });
      
    }
  }
});
