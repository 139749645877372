import Controller from '@ember/controller';
import {
  computed
} from '@ember/object';
import {
  inject as service
} from '@ember/service';

export default Controller.extend({
    vereinonlineRequests: service(),
    filterNiveauTodos: '',
    queryParams: ['queryNiveauTodos', 'filterNiveauTodos'],
    searchProperties: ['vorname', 'nachname'],
    copyText: computed('model.termAnmeldungen.value.@each.{vorname,nachname,hasOpenNiveauTodo}', function(){
      if(this.get('model.termAnmeldungen.value')){
        return this.get('model.termAnmeldungen.value').filter(i => i.get('hasOpenNiveauTodo')).map(i => {
          return `${i.get('vorname')} ${i.get('nachname')}`
        }).join('\r\n')
      }else{
        return ''
      }
    }),
});
