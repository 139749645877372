import Service from '@ember/service';
import {
    task, all
  } from 'ember-concurrency';
import {parse} from 'papaparse';
import { defer } from 'rsvp';
import EmberObject from '@ember/object';
import {
    inject as service
  } from '@ember/service';
  import moment from 'moment';

export default Service.extend({
    store: service(),
    parseEvents: task(function* (file) {
        let deferred = defer();
        var maxKursCount = 0;
        parse(file, {
            header: true,
            dynamicTyping: true,
            encoding: "ISO-8859-1",
            skipEmptyLines: true,
            complete: deferred.resolve,
            transformHeader: function(header){
                if(header == "maxKurse" || header == "maxAthKurse" || header == "maxkurse"){
                    maxKursCount++;
                    return "maxKurse"+maxKursCount;
                }else{
                    return header;
                }
            }
        });
        let data = yield deferred.promise;
        let out = [];
        let lastTeilnehmer = false;
        data.data.forEach(item => {
            item = EmberObject.create(item);
            if(item.get('id')){
                lastTeilnehmer = [];
                item.set('teilnehmer', lastTeilnehmer);
                out.pushObject(item);
            }else{
                for(let i = 1; i <= maxKursCount; i++){
                    if(item.get('maxKurse'+i)){
                        item.set('maxKurse', item.get('maxKurse'+i));
                    }
                }
                lastTeilnehmer.pushObject(item);
            }
        })
        return out;
    }),
    importEvents: task(function* (term, data, importStatus = false) {

        let teilnehmer = data.reduce((previousValue, item) => {
            return previousValue.pushObjects(item.get('teilnehmer'));
        }, []);
        let teilnehmerWithId = teilnehmer.filter(item => item.get('Mitglieder-ID')+"" != "");
        teilnehmerWithId = teilnehmerWithId.uniqBy('Mitglieder-ID');
        let teilnehmerWithoutId = teilnehmer.filter(item => !item.get('Mitglieder-ID') || item.get('Mitglieder-ID')+"" == "");
        teilnehmerWithoutId = teilnehmerWithoutId.uniqBy('E-Mail');

        yield all(teilnehmerWithId.map(item => {
            return this.get('importTeilnehmer').perform(item);
        }));

        yield all(teilnehmerWithoutId.map(item => {
            return this.get('importTeilnehmer').perform(item);
        }));

        let events = yield all(data.map(item => {
            return this.get('importEvent').perform(item, false, importStatus);
        }));
        let oldEvents = term.get('events');
        events.forEach(item => {
            if(!oldEvents.includes(item)){
                oldEvents.pushObject(item);
            }
        })
        yield term.save();
    }),
    importEvent: task(function* (data, importTeilnehmer=false, importStatus = false) {
        let event;
        try {
            event = yield this.get('store').findRecord('event', data.get('id'));
        }catch(error){
            if(!error.message.startsWith('no record was found')){
                throw error;
            }
            event = yield this.get('store').createRecord('event', {id: data.get('id')});
        }
        event.set('datum', moment(data.get('datum'), 'DD.MM.YYYY').toDate());
        event.set('titel', data.get('titel'));
        event.set('ort', data.get('ort'));
        event.set('zeit', data.get('zeit'));
        event.set('anzahltage', data.get('anzahltage'));
        event.set('beschreibung', data.get('beschreibung'));
        event.set('akid', data.get('akid'));
        event.set('typ', data.get('typ'));
        event.set('maxpersonen', data.get('maxpersonen'));
        if(data.get('anmeldebeginn') != "0000-00-00 00:00:00"){
            event.set('anmeldebeginn',  moment(data.get('anmeldebeginn'), 'YYYY-MM-DD HH:mm:ss').toDate());
        }else{
            event.set('anmeldebeginn',  null);
        }
        if(data.get('anmeldeschluss') != "00.00.0000"){
            event.set('anmeldeschluss',  moment(data.get('anmeldeschluss'), 'DD.MM.YYYY').toDate());
        }else{
            event.set('anmeldeschluss',  null);
        }
        if(data.get('abmeldeschluss') != "00.00.0000"){
            event.set('abmeldeschluss',  moment(data.get('abmeldeschluss'), 'DD.MM.YYYY').toDate());
        }else{
            event.set('abmeldeschluss',  null);
        }
        event.set('warteliste', data.get('warteliste'));
        event.set('userid', data.get('userid'));
        event.set('kontakt', data.get('kontakt'));
        event.set('key_Veranstaltungstyp', data.get('key_veranstaltungstyp'));
        event.set('key_Art', data.get('key_art'));
        event.set('key_Niveau', data.get('key_niveau'));
        event.set('key_Eventtyp', data.get('key_eventtyp'));
        if(event.get('isNew') || event.get('hasDirtyAttributes')){
            yield event.save();
        }
        if(importTeilnehmer){
            yield all(data.get('teilnehmer').map(item => {
                return this.get('importTeilnehmer').perform(item);
            }));
        }

        yield all(data.get('teilnehmer').map(item => {
            return this.get('importAnmeldung').perform(event, item, importStatus);
        }));

        //TODO: Remove deleted anmeldungen

        return event;
    }),

    importTeilnehmer: task(function* (data) {
        let teilnehmer;
        try {
            teilnehmer = yield this.get('findTeilnehmerByIDOrEmail').perform(data.get('Mitglieder-ID'), data.get('E-Mail'));
        }catch(error){
            if(!error.message.startsWith('no record was found')){
                throw error;
            }
        }

        if(!teilnehmer){
            if(data.get('Mitglieder-ID') && data.get('Mitglieder-ID')+"" != ""){
                teilnehmer = yield this.get('store').createRecord('teilnehmer', {id:data.get('Mitglieder-ID')+""});
            }else{
                teilnehmer = yield this.get('store').createRecord('teilnehmer');
            }
        }

        teilnehmer.set('nachname', data.get('Nachname'));
        teilnehmer.set('vorname', data.get('Vorname'));
        teilnehmer.set('rolle', data.get('Rolle'));
        teilnehmer.set('email', data.get('E-Mail'));
        if(teilnehmer.get('isNew') || teilnehmer.get('hasDirtyAttributes')){
            yield teilnehmer.save();
        }
        return teilnehmer;
    }),

    importAnmeldung: task(function* (event, data, importStatus = false) {
        event = yield event;
        let anmeldungen = yield event.get('anmeldungen');
        yield all(anmeldungen.map(item => item.get('teilnehmer')));
        let teilnehmer = yield this.get('findTeilnehmerByIDOrEmail').perform(data.get('Mitglieder-ID'), data.get('E-Mail'));
        let anmeldung = anmeldungen.findBy('teilnehmer.id', teilnehmer.get('id'));
        
        if(!anmeldung){
            anmeldung = yield this.get('store').createRecord('anmeldung', {teilnehmer: teilnehmer, event:event, status: 'toBeDetermind'});
        }

        if(data.get('Anmerkungen')){
            anmeldung.set('anmerkungen', data.get('Anmerkungen').replace(/\r?\n|\r/g, ""));
        }else{
            anmeldung.set('anmerkungen', "");
        }
        let aString = data.get('Anmeldezeitpunkt').match(/\d\d.\d\d.\d\d\d\d \d\d:\d\d:\d\d/i)[0];
        if(aString){
            anmeldung.set('anmeldezeitpunkt',  moment(aString, 'DD.MM.YYYY HH:mm:ss').toDate());
        }
        anmeldung.set('maxNOfEvents', data.get('maxKurse'));

        if(importStatus){
            if(data.get('Status') == 'angemeldet'){
                anmeldung.set('status', "included");
            }else if(data.get('Status') == 'abgemeldet'){
                anmeldung.set('status', "excluded");
            }else{
                anmeldung.set('status', "toBeDetermind");
            }
        }

        if(anmeldung.get('isNew') || anmeldung.get('hasDirtyAttributes')){
            yield anmeldung.save();
        }

        if(teilnehmer){
            yield event.save();
            yield teilnehmer.save();
        }

        return anmeldung;
    }),

    importNiveaus: task(function* (file) {
        let deferred = defer();
        parse(file, {
            header: true,
            dynamicTyping: true,
            encoding: "ISO-8859-1",
            skipEmptyLines: true,
            complete: deferred.resolve
        });
        let data = yield deferred.promise;
        data = data.data.map(item => EmberObject.create(item));

        let teilnehmer = data.uniqBy('Mitglied ID');
        yield all(teilnehmer.map(item => {
            return this.get('dummyImportTeilnehmer').perform(item);
        }));

        yield all(data.map(item => {
            return this.get('importNiveau').perform(item);
        }));
       
    }),

    importNiveau: task(function* (data, importTeilnehmer=false) {
        if(data.get('Einschätzung') && data.get('Einschätzung') != "" && data.get('Datum') && data.get('Datum') != ""){
            let teilnehmer;
            if(importTeilnehmer){
                teilnehmer = yield this.get('dummyImportTeilnehmer').perform(data);
            }else{
                teilnehmer = yield this.get('store').findRecord('teilnehmer', data.get('Mitglied ID'));
            }

            let niveau;
            try {
                niveau = yield this.get('store').findRecord('niveaueinschaetzung', data.get('ID'));
            }catch(error){
                if(!error.message.startsWith('no record was found')){
                    throw error;
                }
                niveau = yield this.get('store').createRecord('niveaueinschaetzung', {id:data.get('ID')});
            }
            let datum = moment(data.get('Datum'), 'DD.MM.YYYY HH:mm:ss').toDate();
            niveau.set('niveau', data.get('Einschätzung'));
            niveau.set('datum', datum);
            niveau.set('teilnehmer', teilnehmer);
            niveau.set('einschaetzer', data.get('Einschätzer'));
            if(niveau.get('isNew') || niveau.get('hasDirtyAttributes')){
                yield niveau.save();
            }

            if(!teilnehmer.get('latestNiveauDatum') || datum.getTime() > teilnehmer.get('latestNiveauDatum')){
                teilnehmer.set('latestNiveau', data.get('Einschätzung'));
                teilnehmer.set('latestNiveauDatum', datum);
                teilnehmer.set('latestNiveauEinschaetzer', data.get('Einschätzer'));
            }

            yield teilnehmer.save();
            return niveau;
        }
    }),

    dummyImportTeilnehmer: task(function* (data) {
        let teilnehmer;
        try {
            teilnehmer = yield this.get('store').findRecord('teilnehmer', data.get('Mitglied ID'));
        }catch(error){
            if(!error.message.startsWith('no record was found')){
                throw error;
            }
            teilnehmer = yield this.get('store').createRecord('teilnehmer', {id:data.get('Mitglied ID')});
            yield teilnehmer.save();
        }
        return teilnehmer;
    }),

    findTeilnehmerByIDOrEmail: task(function* (id, email){
        if(id && id != null && id+"" != ""){
            return yield this.get('store').findRecord('teilnehmer', id);
        }else if(email && email != ""){
            let records =  yield this.get('store').query('teilnehmer', {
                orderBy: 'email',
                equalTo: email
              });
            return records.get('firstObject');
        }else{
            return null;
        }
    }),

    filterEvents: task(function* (data, filter, eventIDs) {
        if(filter == "all"){
            data.forEach(item => {
                item.set('selected', true);
            });
        }else if(filter == "none"){
            data.forEach(item => {
                item.set('selected', false);
            });
        }else if(filter == "existing" && eventIDs){
            data.forEach(item => {
                if(eventIDs.includes(item.get('id')+'')){
                    item.set('selected', true);
                }else{
                    item.set('selected', false);
                }
            });
        }else{
            const types = filter.split("|");
            if(eventIDs){
                data.forEach(item => {
                    if((types.includes(item.get('key_eventtyp')) && item.get('serienveranstaltungid') == 0) || eventIDs.includes(item.get('id')+'')){
                        item.set('selected', true);
                    }else{
                        item.set('selected', false);
                    }
                });
            }else{
                data.forEach(item => {
                    if(types.includes(item.get('key_eventtyp')) && item.get('serienveranstaltungid') == 0){
                        item.set('selected', true);
                    }else{
                        item.set('selected', false);
                    }
                });
            }
        }
    }),

});
