import Service from '@ember/service';
import {
    inject
  } from '@ember/service';
  import {
    task
  } from 'ember-concurrency';

export default Service.extend({
    firebase: inject(),

    openEmailWebsiteWithEvents: task(function* (events) {
      const ids = yield events.map(i => i.get('anmeldungen').map(j => j.get('termAnmeldung.teilnehmer.id'))).flat() 
      this.get('openEmailWebsite').perform(ids)
    }),

    openEmailWebsiteWithTermanmeldung: task(function* (termAnmeldung) {
      const ids = yield [termAnmeldung.get('teilnehmer.id')]
      this.get('openEmailWebsite').perform(ids)
    }),

    openEmailWebsiteWithTermanmeldungen: task(function* (termAnmeldungen) {
      const ids = yield termAnmeldungen.map(i => i.get('teilnehmer.id'))
      this.get('openEmailWebsite').perform(ids)
    }),

    openEmailWebsiteWithAnmeldungen: task(function* (anmeldungen) {
      const ids = yield anmeldungen.map(i => i.get('termAnmeldung.teilnehmer.id'))
      this.get('openEmailWebsite').perform(ids)
    }),

    openEmailWebsiteWithAnmeldungenForOneEvent: task(function* (anmeldungen, eventID) {
      const ids = yield anmeldungen.map(i => i.get('id'))
      this.get('openEmailWebsite').perform(ids, eventID)
    }),

    openEmailWebsite: task(function* (ids, eventID=false) {
      var form = document.createElement("form");
      form.target = "_blank";
      form.method = "POST";
      form.action = "https://www.vereinonline.org/BeachL_eV/";
      form.style.display = "none";

      const data = {
        action: "members_sendmailsuche",
        cmd: "-",
      }

      if(eventID){
        data.action = "events_anmeldungenaktion"
        data.cmd = "mail"
        data.id = eventID
      }

      for (var key in data) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
    }

      ids.forEach(id => {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = 'check_'+id;
        input.value = 1;
        form.appendChild(input);
      });
  
      document.body.appendChild(form);
      yield form.submit();
      document.body.removeChild(form);
    }),

    openRollenAndFelderSetzenWithTermanmeldungen: task(function* (termAnmeldungen) {
      const ids = yield termAnmeldungen.map(i => i.get('teilnehmer.id'))
      this.get('openRollenAndFelderSetzen').perform(ids)
    }),

    openRollenAndFelderSetzen: task(function* (ids) {
      var form = document.createElement("form");
      form.target = "_blank";
      form.method = "POST";
      form.action = "https://www.vereinonline.org/BeachL_eV/";
      form.style.display = "none";

      const data = {
        action: "members_zuweisen",
        cmd: "-",
      }

      for (var key in data) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
    }

      ids.forEach(id => {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = 'check_'+id;
        input.value = 1;
        form.appendChild(input);
      });
  
      document.body.appendChild(form);
      yield form.submit();
      document.body.removeChild(form);
    }),
});
