import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';

export default Controller.extend({
    vereinonlineRequests: service(),
    apiFunctions: service(),
    router: service(),
});
