import {
    inject
  } from '@ember/service';
  import Component from '@ember/component';
  
  import firebase from 'firebase';
  import {
    task
  } from 'ember-concurrency';
  
  export default Component.extend({
    session: inject(),
    firebase: inject(),
    auth: inject(),
    apiFunctions: inject(),
    username: '',
    password: '',

    login: task(function* () {
      
      try {
        const user = yield this.get('apiFunctions.login').perform(this.get('username'), this.get('password'))
        yield this.get('session').open('firebase', {provider: "custom", token: user.data.token})
        yield this.get('auth').trigger('signInSuccess', 'signInSuccess');
      }catch(e){
        if (e.message === "Error: Error Accessing VO-API: fehlerhaft"){
          throw new Error('Falscher Nutzername oder Passwort');
        }
        throw e
      }
    }),

    closeDialog: task(function* () {
      yield this.get('auth').trigger('cancelSignIn', 'cancelSignIn');
    })
  });
  