import EmberRouter from '@ember/routing/router';
import config from './config/environment';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL
});

Router.map(function() {
  this.route('term', function() {
    this.route('add', function() {});
    this.route('term', { path: '/:term_id' }, function() {
      this.route('event', { path: '/event/:event_id' });
      this.route('anmeldung-optimizer', { path: '/optimizer/:event_ids' });
      this.route('process-steps', function() {
        this.route('step', {path: '/:step_id'});
      });
      this.route('todos');
      this.route('niveau-todo');
      this.route('teilnehmer');
      this.route('trainer');
      this.route('term-anmeldung', { path: '/teilnehmer/:term_anmeldung_id' }, function(){
        this.route('events');
        this.route('niveaus');
        this.route('todos');
        this.route('niveau-todo');
        this.route('other-events');
      });
      this.route('absagen');
      this.route('anmeldungen');
      this.route('settings');
      this.route('stat', function() {
        this.route('final');
        this.route('without');
        this.route('working');
        this.route('oneevent');
        this.route('absagen');
      });
    });
  });
});

export default Router;
