  import Component from '@ember/component';
  import {
    computed,
    observer
  } from '@ember/object';
  import {
    inject
  } from '@ember/service';
  import {
    task, all
  } from 'ember-concurrency';
  
  export default Component.extend({
    store: inject(),
    message: '',
    anmeldung: computed('anmeldungen.@each.isToggleLockAnmeldungRunning', function(){
      if(this.get('anmeldungen')){
        return this.get('anmeldungen').findBy('isToggleLockAnmeldungRunning', true)
      }else{
        return false
      }
    }),
    show: computed('anmeldung.toggleLockAnmeldung.last.progress', function(){
      return this.get('anmeldung') && this.get('anmeldung.toggleLockAnmeldung.last.progress') === 'waitingForMessage'
    }),
    onClose() {
      this.get('anmeldung.toggleLockAnmeldung').cancelAll()
    },
    onAbsenden() {
      this.get('anmeldung.toggleLockAnmeldung.last').setMessage(this.get('message'))
    }
  });
  