import Component from '@ember/component';
import {
  inject
} from '@ember/service';
import {
  computed
} from '@ember/object';
import BEMComponent from 'ember-bem-sauce/mixins/bem-component';

export default Component.extend(BEMComponent, {
  base: 'save-indicator',
  classNames: ['layout-row'],
  saveState: inject(),
  apiFunctions: inject(),
  isRunning: computed('saveState.prolongedIsSaving', 'apiFunctions.isRunning', function(){
    return this.get('saveState.prolongedIsSaving') || this.get('apiFunctions.isRunning')
  })
});
