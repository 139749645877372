import Service from '@ember/service';
import {
    inject
  } from '@ember/service';

  import {
    task,
    waitForEvent,
    race
  } from 'ember-concurrency';
import { all } from 'rsvp';
import Evented from '@ember/object/evented';

export default Service.extend(Evented, {
    session: inject(),
    firebase: inject(),
    store: inject(),
    router: inject(),
    ownedModelNames: [],
    login: task({
        internalState: null,
        *perform() {
            let event = false;
            let context = this.get('context');

            try {
                //Prepare
                this.set('internalState', 'prepare');
                

                //Waiting for FirebaseUI Login
                this.set('internalState', 'waitingForLogin');
                event = yield race([waitForEvent(context, 'signInSuccess'), waitForEvent(context, 'cancelSignIn')]);

            } finally {
                //Finish
                if (event == 'signInSuccess') {
                    this.set('internalState', 'finish');
                    yield context.get('session').fetch().catch(function () {});
                    this.set('internalState', 'finished');
                }else{
                    this.set('internalState', 'cancel');

                    this.set('internalState', 'canceled');
                }
            } 
        },
      }).drop(),
      logout: task(function* () {
        let user = yield this.get('session.currentUser');
        if(user){
            yield this.get('_haltSubscribtionOfOwnedModels').perform();
            yield this.get('_haltSubscribtionOfUserModel').perform(user);
            yield this.get('_unloadOwnedModels').perform();
            yield this.get('session').close();
            yield this.get('_unloadUserModel').perform(user);
            this.trigger('logoutSuccess', 'logoutSuccess');
        }else{
            return; //TODO: Error?
        }
      }).drop(),
    _haltSubscribtionOfOwnedModels: task(function* () {
        let store = yield this.get('store');
        this.get('ownedModelNames').forEach(modelName => {
            let adapter = store.adapterFor(modelName);
            store.peekAll(modelName).forEach(model => {
                adapter.stopListening(store, model.constructor, model);
            });
        });
    }),
    _reactivateSubscribtionOfOwnedModels: task(function* () {
        let store = yield this.get('store');
        this.get('ownedModelNames').forEach(modelName => {
            let adapter = store.adapterFor(modelName);
            store.peekAll(modelName).forEach(model => {
                adapter.listenForChanges(store, model.constructor, model);
            });
        });
    }),
    _unloadOwnedModels: task(function* () {
        let store = yield this.get('store');
        this.get('ownedModelNames').forEach(modelName => {
            store.unloadAll(modelName);
        });
    }),
    _reloadOwnedModels: task(function* () {
        let store = yield this.get('store');
        let out = [];
        this.get('ownedModelNames').forEach(modelName => {
            store.peekAll(modelName).forEach(model => {
                out.pushObject(model.reload());
            });
        });
        yield all(out);
    }),
    _haltSubscribtionOfUserModel: task(function* (user) {
        let store = yield this.get('store');
        let adapter = store.adapterFor('user');
        adapter.stopListening(store, user.constructor, user);
    }),
    _reactivateSubscribtionOfUserModel: task(function* (user) {
        let store = yield this.get('store');
        let adapter = store.adapterFor('user');
        adapter.listenForChanges(store, user.constructor, user);
    }),
    _unloadUserModel: task(function* (user) {
        yield user.unloadRecord();
    })


});
