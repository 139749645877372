import Controller from '@ember/controller';
import {
  computed
} from '@ember/object';
import {
  inject as service
} from '@ember/service';

export default Controller.extend({
    vereinonlineRequests: service(),
    apiFunctions: service(),
    filterTeilnehmer: '',
    queryParams: ['queryTeilnehmer', 'filterTeilnehmer'],
    searchProperties: ['vorname', 'nachname', 'latestNiveau'],
    actions:{
      extendedSortKey(key){
        if(key == 'kurs:desc'){
          return ['lengthIncludedAnmeldungen:desc', 'lengthToBeDetermindAnmeldungen:desc'];
        }else if(key == 'kurs:asc'){
          return ['lengthIncludedAnmeldungen:asc', 'lengthToBeDetermindAnmeldungen:asc'];
        }else{
          return key;
        }
      }
    },
    filteredTermAnmeldungen: computed('filterTeilnehmer', 'model.termAnmeldungen.value', 'model.term.value.{teilnehmerWithoutEvent,prospectWithEvent,teilnehmerWithTodo,teilnehmerWithEventAtTheSameDay}', function(){
      if(this.get('model.term.value')){
        if(this.get('filterTeilnehmer') === 'withEvent'){
          return this.get('model.term.value.teilnehmerWithEvent')
        }else if(this.get('filterTeilnehmer') === 'withoutEvent'){
          return this.get('model.term.value.teilnehmerWithoutEvent')
        }else if(this.get('filterTeilnehmer') === 'withoutEventOrMaybeWithout'){
          return this.get('model.term.value.teilnehmerWithoutEventOrMaybeWithout')
        }else if(this.get('filterTeilnehmer') === 'prospectWithEvent'){
          return this.get('model.term.value.prospectWithEvent')
        }else if(this.get('filterTeilnehmer') === 'withTodo'){
          return this.get('model.term.value.teilnehmerWithTodo')
        }else if(this.get('filterTeilnehmer') === 'withInterestForMoreEvents'){
          return this.get('model.term.value.teilnehmerWithInterestForMoreEvents')
        }else if(this.get('filterTeilnehmer') === 'withEventAtTheSameDay'){
          return this.get('model.term.value.teilnehmerWithEventAtTheSameDay')
        }else{
          return this.get('model.termAnmeldungen.value')
        }
      }else{
        return false
      }
    }),
    copyText: computed('filteredTermAnmeldungen.@each.{vorname,nachname}', function(){
      if(this.get('filteredTermAnmeldungen')){
        return this.get('filteredTermAnmeldungen').map(i => {
          return `${i.get('vorname')} ${i.get('nachname')}`
        }).join('\r\n')+''
      }else{
        return ''
      }
    }),
});
