import Controller from '@ember/controller';
import {
    task,
    all
  } from 'ember-concurrency';
  import {
    computed
  } from '@ember/object';
  import EmberObject from '@ember/object';
  import {
    inject as service
  } from '@ember/service';
  import exportFromJSON from 'export-from-json'

export default Controller.extend({
    automatedAllocation: service(),
    vereinonlineRequests: service(),
    queryParams: [{
        filter: {
            type: 'string'
        },
        queryEvents: {
            type: 'string'
        }
    }],
    searchProperties: ['titel', 'zeit', 'tag'],
    termsPlusAdd: computed('model.terms.value.[]', function(){
        let terms = [];
        if(this.get('model.terms.value')){
            terms.pushObjects(this.get('model.terms.value').map(item => item));
        }
        //terms.pushObject(EmberObject.create({id: 'add', name: 'Hinzufügen'}));
        return terms;
    }),
    filteredEvents: computed('model.events.value.@each.{isTodo,spacesLeft}', 'filter', function(){
        if(this.filter == "spacesLeft"){
            return this.model.events.value.filterBy('spacesLeft');
        }else if(this.filter == "done"){
            return this.model.events.value.filterBy('isTodo', false)
        }else if(this.filter == "all"){
            return this.model.events.value;
        }else{
            return this.model.events.value.filterBy('isTodo');
        }
    }),
    selectTerm: task(function* (term) {
        if(term.get('id') === 'add'){
            this.transitionToRoute('term.add');
        }else{
            this.transitionToRoute('term.term', term.get('id'));
        }
        yield true
    }),
    setFilter: task(function* (filter) {
        this.set('filter', filter)
        yield true
    }),
    exportEventsAsCSV: task(function* (events){
        const data = []
        yield all(events.map(e => e.get('trainer')))
        events.forEach(e => {
            data.pushObject({
                id: e.get('id'),
                tag: e.get('tag'),
                titel: e.get('titel'),
                trainerVorname: e.get('trainer.firstObject.vorname'),
                trainerNachname: e.get('trainer.firstObject.nachname'),
                trainerGeschlecht: e.get('trainer.firstObject.gender'),
                zeit: e.get('zeit')
            })
            e.get('includedAnmeldungen').forEach(a => {
                data.pushObject({
                    Status: a.get('status'),
                    Nachname: a.get('nachname'),
                    Vorname: a.get('vorname'),
                    'E-Mail': a.get('email'),
                    'Geschlecht': a.get('gender')
                })
            })
        })
        yield exportFromJSON({ data, fileName:`events-${this.get('model.term.value.name')}`, exportType:exportFromJSON.types.csv })
    })
});
