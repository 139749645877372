import DS from 'ember-data';
import IndicateSaveing from '../mixins/indicate-saving-model';

export default DS.Model.extend(IndicateSaveing, {
  name: DS.attr('string'),
  text: DS.attr('string'),
  status: DS.attr('string'),
  primary: DS.attr('boolean'),
  activityLevels: DS.hasMany('activity-level', { async: true, inverse: null }),
  numberOfInculsions: DS.attr('string'),
});
