  import Component from '@ember/component';
  import {
    computed,
    observer
  } from '@ember/object';
  import {
    inject
  } from '@ember/service';
  import {
    task, all
  } from 'ember-concurrency';
  
  export default Component.extend({
    store: inject(),
    message: '',
    anmeldung: computed('anmeldungen.@each.isConfirmAnmeldungRunning', function(){
      if(this.get('anmeldungen')){
        return this.get('anmeldungen').findBy('isConfirmAnmeldungRunning', true)
      }else{
        return false
      }
    }),
    anmeldungChanged: observer('anmeldung.{statusPlusGrund,lengthIncludedAnmeldungen}', function() {
      const status = this.get('anmeldung.statusPlusGrund')
      const lengthIncludedAnmeldungen = this.get('anmeldung.lengthIncludedAnmeldungen')
      const vorlagen = this.get('vorlagen').filterBy('primary').filterBy('status', status)
      const activityLevelID = this.get('anmeldung.termAnmeldung.activityLevel.id')
      let v = []
      if(activityLevelID){
        const vorlagenWithActivity = vorlagen.filter(i => i.get('activityLevels.length') > 0)
        v = vorlagenWithActivity.filter(i => i.hasMany('activityLevels').ids().includes(activityLevelID)).sortBy('activityLevels.length')
      }
      if(!v || v.get('length') === 0){
        v = vorlagen.filterBy('activityLevels.length', 0)
      }
      let v2 = []
      if(v && v.get('length') > 0){
        if(lengthIncludedAnmeldungen > 1){
          v2 = v.filter(i => i.get('numberOfInculsions') === '2+')
        }else if(lengthIncludedAnmeldungen === 1){
          v2 = v.filter(i => i.get('numberOfInculsions') === '1')
        }else if(lengthIncludedAnmeldungen === 0){
          v2 = v.filter(i => i.get('numberOfInculsions') === '0')
        }
        if(!v2 || v2.get('length') === 0){
          v2 = v.filter(i => !i.get('numberOfInculsions') || i.get('numberOfInculsions') === '')
        }
      }
      if(v2 && v2.get('length') > 0){
        this.set('message', v2.get('firstObject').get('text'))
      }else{
        this.set('message', '')
      }
    }),
    show: computed('anmeldung.confirmAnmeldung.last.progress', function(){
      return this.get('anmeldung') && this.get('anmeldung.confirmAnmeldung.last.progress') === 'waitingForMessage'
    }),
    onClose() {
      this.get('anmeldung.confirmAnmeldung').cancelAll()
    },
    onAbsenden() {
      this.get('anmeldung.confirmAnmeldung.last').setMessage(this.get('message'))
    },
    editVorlage: false,
    addVorlage: task(function* () {
      const record = yield this.get('store').createRecord(`email-vorlage`, {status:this.get('anmeldung.statusPlusGrund')})
      this.set(`editVorlage`, record)
    }).drop(),
    vorlageVerwerfen() {
      this.get('editVorlage').rollbackAttributes()
      this.set(`editVorlage`, false)
    },
    vorlageLoeschen: task(function* () {
      yield this.get('editVorlage').destroyRecord()
      this.set(`editVorlage`, false)
    }).drop(),
    vorlageSpeichern: task(function* () {
      const v = this.get('editVorlage')
      yield v.save()
      this.set(`editVorlage`, false)
    }).drop(),
    removeActivityLevel: task(function* (level) {
      const v = this.get('editVorlage')
      v.get('activityLevels').removeObject(level)
    }),
    addActivityLevel: task(function* (level) {
      const v = this.get('editVorlage')
      v.get('activityLevels').pushObject(level)
    }),
  });
  