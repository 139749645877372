import Route from '../-base';
import {
  task,
  all
} from 'ember-concurrency';

export default Route.extend({
    model(params) {
      const term = this.get('findTerm').perform(params.term_id);
      const events = this.get('findEvents').perform(term);
      const anmeldungen = this.get('findAnmeldungen').perform(term, events);
      const termAnmeldungen = this.get('findTermAnmeldungen').perform(term)
      const teilnehmer = this.get('findTeilnehmer').perform(termAnmeldungen);
      const processSteps = this.get('loadProcessSteps').perform(term)
      const anmeldungPriotitys = this.get('loadAnmeldungPriotitys').perform(anmeldungen)
      return {
        terms: this.get('findTerms').perform(),
        activityLevel: this.get('findActivityLevels').perform(),
        term: term,
        events: events,
        anmeldungen: anmeldungen,
        termAnmeldungen: termAnmeldungen,
        teilnehmer: teilnehmer,
        allLoadedForStatus: this.get('allLoadedForStatus').perform(teilnehmer, anmeldungen, this.get('laodNiveaus').perform(events), processSteps, anmeldungPriotitys),
        vorlagen: this.get('findVorlagen').perform(),
        trainer: this.get('findTrainer').perform(term, events),
        processSteps: processSteps,
        anmeldungPriotitys
      };
    },
    findTerms: task(function* () {
        return yield this.get('store').findAll('term');
    }),
    findActivityLevels: task(function* () {
      return yield this.get('store').findAll('activity-level');
    }),
    findTerm: task(function* (term_id) {
        return yield this.get('store').findRecord('term', term_id);
    }),
    findEvents: task(function* (term) {
        term = yield term;
        return yield term.get('events');
    }),
    findAnmeldungen: task(function* (term, events) {
        term = yield term;
        events = yield events;
        yield all(events.map(item => item.get('anmeldungen')));
        return term.get('sortedAnmeldungen');
    }),
    findTermAnmeldungen: task(function* (term) {
      term = yield term;
      return yield term.get('termAnmeldungen');
  }),
    findTeilnehmer: task(function* (anmeldungen) {
        anmeldungen = yield anmeldungen;
        return yield all(anmeldungen.map(item => item.get('teilnehmer')));
    }),
    laodNiveaus: task(function* (events) {
      events = yield events;
      yield all(events.map(item => item.get('niveau')));
      return true
    }),
    loadProcessSteps: task(function* (term) {
      term = yield term;
      return yield term.get('processSteps');
    }),
    loadAnmeldungPriotitys: task(function* (anmeldungen) {
      anmeldungen = yield anmeldungen;
      yield all(anmeldungen.map(item => item.get('_priority')));
      return true
    }),
    allLoadedForStatus: task(function* (teilnehmer, anmeldungen, niveaus, processSteps, anmeldungPriotitys) {
      yield anmeldungen;
      yield teilnehmer;
      yield niveaus;
      yield processSteps;
      yield anmeldungPriotitys;
      return true;
    }),
    findVorlagen: task(function* () {
      return yield this.get('store').findAll('email-vorlage');
    }),
    findTrainer: task(function* (term, events) {
      term = yield term
      events = yield events
      yield all(events.map(e => e.get('trainer')))
      return term.get('trainer')
  }),
})
