import DS from 'ember-data';

export default DS.Transform.extend({
  deserialize(serialized) {
    var d = new Date();
    d.setTime(serialized);
    return d;
  },

  serialize(deserialized) {
    if(deserialized instanceof Date){
      return deserialized.getTime();
    }else if (deserialized === undefined){
      return null;
    }else {
      return deserialized;
    }
  }
});
