import Route from '../../-base';
import {
  task,
  all
} from 'ember-concurrency';

export default Route.extend({
    model(params) {
      const parentModel = this.modelFor('term.term');
      const events = this.get('findEvents').perform(params.event_ids.split(".").compact());
      const anmeldungen = this.get('findAnmeldungen').perform(events);
      const termAnmeldung = this.get('findTermAnmeldung').perform(anmeldungen);
      const teilnehmer = this.get('findTeilnehmer').perform(termAnmeldung);
      return {
        term: parentModel.term,
        activityLevel: parentModel.activityLevel,
        events: events,
        anmeldungen: anmeldungen,
        termAnmeldung: termAnmeldung,
        teilnehmer: teilnehmer,
        allLoadedForStatus: parentModel.allLoadedForStatus,
        vorlagen: parentModel.vorlagen
      };
    },
    findEvents: task(function* (event_ids) {
        return yield all(event_ids.map(id => this.get('store').findRecord('event', id)));
    }),
    findAnmeldungen: task(function* (events) {
        events = yield events;
        const anmel = yield all(events.map(event => event.get('anmeldungen')));
        return anmel.reduce((summation, current) => {
          if(current){
            return summation.pushObjects(current.toArray());
          }
          return summation
        }, [])
    }),
    findTermAnmeldung: task(function* (anmeldungen) {
        anmeldungen = yield anmeldungen;
        const termAnmeldungen = yield all(anmeldungen.map(item => item.get('termAnmeldung')));
        return termAnmeldungen.uniqBy('id')
    }),
    findTeilnehmer: task(function* (termAnmeldung) {
      termAnmeldung = yield termAnmeldung;
      yield all(termAnmeldung.map(item => item.get('teilnehmer')));
  }),
})

