import Controller from '@ember/controller';
import {
    task
  } from 'ember-concurrency';
  import {
    computed
  } from '@ember/object';
  import EmberObject from '@ember/object';
import {
    inject as service
  } from '@ember/service';

export default Controller.extend({
    vereinonlineImporter: service(),
    fileSelected: task(function* (files) {
        let data = yield this.get('vereinonlineImporter.parseEvents').perform(files[0]);
        this.get('vereinonlineImporter.filterEvents').perform(data, "Training|Training (Athletik)");
        this.set('data', data);
    }),
    filter: task(function* (filter) {
        yield this.get('vereinonlineImporter.filterEvents').perform(this.data, filter);
    }),
    createTerm: task(function* () {
        let term = yield this.get('store').createRecord('term', {name:this.get('name'), lastTerm:this.get('lastTerm'), archived:false});
        yield term.save();
        let data = this.get('data').filterBy('selected');
        yield this.get('vereinonlineImporter.importEvents').perform(term, data, true);
        this.set('name', null);
        this.set('data', null);
        this.set('lastTerm', null);
        this.transitionToRoute('term.term', term.get('id'));
    }),
    termsPlusAdd: computed('model.terms.value.[]', function(){
        let terms = [];
        if(this.get('model.terms.value')){
            terms.pushObjects(this.get('model.terms.value').map(item => item));
        }
        //terms.pushObject(EmberObject.create({id: 'add', name: 'Hinzufügen'}));
        return terms;
    }),
    selectTerm: task(function* (term) {
        if(term.get('id') === 'add'){
            this.transitionToRoute('term.add');
        }else{
            this.transitionToRoute('term.term', term.get('id'));
        }
        yield true
    }),
});
