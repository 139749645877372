import Mixin from '@ember/object/mixin';
import {
  inject
} from '@ember/service';
import {
  next
} from '@ember/runloop';

export default Mixin.create({
  saveState: inject(),
  init() {
    this._super(...arguments);
    next(this, function () {
      this.get('saveState').addModel(this);
    });
  },
  willDestroy(){
    this.get('saveState').removeModel(this);
    this._super(...arguments);
  }
});
