import Component from '@ember/component';
import {
    computed
  } from '@ember/object';

export default Component.extend({
    tagName: 'span',
    numberOfTodos: computed('todos.@each.status', function(){
        if(this.get('todos')){
            return this.get('todos').filter(i => i.status !== 'Done').length
        }
        return 0
    })
});