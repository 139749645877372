 import Component from '@ember/component';

 /**
  * @class PaperList
  * @extends Ember.Component
  */
 export default Component.extend({
   tagName: 'md-list',
   classNames: ['md-default-theme'],
   dragging: false,
   dragPosition: 0,
   dragItemHight: 0,
   startDragging(e, height){
        this.set('y', e.clientY)
        this.set('dragItemHight', height)
       this.set('dragging', true)
       this.set('mouseMove', this.get('mouseMoveInactive'))
   },
   stopDragging(){
       this.set('dragging', false)
       this.set('mouseMove', undefined)
       return (this.get('dragPosition'))
   },
   mouseMoveInactive(e){
        this.set('y', e.clientY)
   },
   setDragPlace(position){
       this.set('dragPosition', position)
   }
 });