 import Service from '@ember/service';
import {
  task,
  all
} from 'ember-concurrency';
import { sortArray } from '../helper-functions/array';
import {
  inject
} from '@ember/service';

export default Service.extend({
  paperToaster: inject(),
  router: inject(),
  rollTheDiceOnEvent: task(function* (event, term, type='all') {
    yield this.get('rollTheDiceOnEvents').perform([event], term, type);
  }),
  rollTheDiceOnAllEvents: task(function* (term, type='all') {
    term = yield term;
    const allEvents = yield term.get('events');
    yield this.get('rollTheDiceOnEvents').perform(allEvents.toArray(), term, type);
  }),
  rollTheDiceOnEvents: task(function* (events, term, type='all') {
    //console.log('-----------------');
    term = yield term;
    events = yield all(events);
    const allEvents = yield term.get('events');
    yield all(allEvents.map(item => item.get('anmeldungen')));
    const allTermAnmeldungen = yield term.get('termAnmeldungen');
    yield all(allTermAnmeldungen.map(item => item.get('teilnehmer')));
    let goOn = false;
    let count = type === 'OneStep' ? 1 : 10000;
    do{
      goOn = yield this.get('_onceRollTheDiceOnEventsInclude').perform(events, type);
      count --;
    }while(goOn && count > 0);
  }),
  _onceRollTheDiceOnEventsInclude: task(function* (events, type='all') {
    const router = this.get('router')
    //Only get events with space left and not on Hold
    const eventsWithSpacesLeft = events.filter(item => item.get('spacesLeft') > 0 && !item.get('onHold'));
    //Abord if none
    if(eventsWithSpacesLeft.get('length') == 0) return false;
    //Find all Anmeldungen to be determind of events
    const anmeldungen = eventsWithSpacesLeft.reduce((previousValue, item) => previousValue.pushObjects(item.get('toBeDetermindAnmeldungen').map(item => item)), []);
    //Abort if none
    if(anmeldungen.get('length') == 0) return false;
    //Find current Round
    const round = anmeldungen.reduce((out, current) => {
      if(!out){
        return current.get('runde')
      }else if(current.get('runde') < out){
        return current.get('runde')
      }else{
        return out
      }
    }, false);
    //Abort if only round one should be allocated and round one is over
    if(type === 'RoundOne' && round > 1) return false
    //Find Event where there are enough spaces for Anmeldungen of the current round and the
    const eventToClose = eventsWithSpacesLeft.reduce((previousValue, item) => {
      const l = item.get('toBeDetermindAnmeldungen').filterBy('runde', round).get('length')
      if(item.get('spacesLeft') >= l && l>0){
        if(!previousValue){
          return item
        }else{
          const lp = previousValue.get('toBeDetermindAnmeldungen').filterBy('runde', round).get('length')
          if(l/item.get('spacesLeft') < lp/previousValue.get('spacesLeft')){
            return item
          }else{
            return previousValue
          }
        }
      }else{
        return previousValue
      }
    }, false);
    if(eventToClose){
      console.log('Close Event', eventToClose.get('titel'))
      if(type === 'OneStep'){
        this.get('paperToaster').show('Alle restlichen Anmeldungen ' +' für Kurs '+eventToClose.get('titel')+' bestätigt', {
          position: 'top right',
          duration: 3000,
          action: {
            label: 'Event',
            accent: true,
            onClick() {
              router.transitionTo('term.term.event', eventToClose.get('id'))
            }
          }
        });
      }
      //Set Anmeldungen
      eventToClose.get('toBeDetermindAnmeldungen').filterBy('runde', round).map(item => item.set('statusPlusGrund', 'included'))
      return true
    }
    //If no Event can be closed, find anmeldung with highest prio
    const anmeldungenOfRound = anmeldungen.filterBy('runde', round)
    const luckyAnmeldungen = this.sortAnmeldungen(anmeldungenOfRound)[0]
    if(type === 'OneStep'){
      this.get('paperToaster').show('Anmeldung für ' +  luckyAnmeldungen.get('vorname')+' '+luckyAnmeldungen.get('nachname')+' zu Kurs '+luckyAnmeldungen.get('eventTitel')+' bestätigt', {
        position: 'top right',
        duration: 3000,
        action: {
          label: 'Event',
          accent: true,
          onClick() {
            router.transitionTo('term.term.event', luckyAnmeldungen.get('event.id'))
          }
        }
      });
    }
    console.log('include Anmeldung', luckyAnmeldungen.get('vorname'), luckyAnmeldungen.get('nachname'), luckyAnmeldungen.get('eventTitel'))
    if(luckyAnmeldungen.get('isNiveauTodoMaybe')){
      luckyAnmeldungen.set('statusPlusGrund', 'included-temporary')
    }else{
      luckyAnmeldungen.set('statusPlusGrund', 'included')
    }
    yield true
    return true;
  }),

  resetAllEvents: task(function* (term) {
    term = yield term;
    const allEvents = yield term.get('events');
    yield this.get('resetEvents').perform(allEvents.toArray(), term);
  }),
  resetEvents: task(function* (events, term) {
    //console.log('-----------------');
    term = yield term;
    events = yield all(events);
    const allEvents = yield term.get('events');
    yield all(allEvents.map(item => item.get('anmeldungen')));
    const allTermAnmeldungen = yield term.get('termAnmeldungen');
    yield all(allTermAnmeldungen.map(item => item.get('teilnehmer')));
    yield events.forEach(event => {
        event.get('anmeldungen').forEach(anmeldung => {
            if(!anmeldung.get('lock') && (anmeldung.get('status') === 'included' || (anmeldung.get('_status') === 'excluded' && anmeldung.get('_absageGrund')===null))){
              anmeldung.set('statusPlusGrund', 'toBeDetermind');
            }
        });
    });
  }),
  sortAnmeldungen(anmeldungen){
    return sortArray(
              anmeldungen,
              this.get('prioSort'),
            )
  },
  prioSort: ['statusPriority:asc', 'isWish:asc', 'hasMaxNOfEvents:asc', 'onHold:asc', 'isNiveauTodoMaybe:asc', 'runde:asc', 'lengthIncludedAnmeldungen:asc', 'isMitglied:desc', 'aktivenStatus:desc', 'isPrioFromLastTerm:desc', 'termAnmeldungRandom:desc', 'userPriority:asc', 'random:desc'],
});
