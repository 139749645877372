import DS from 'ember-data';
import {
  computed
} from '@ember/object';
import { filterBy, alias, sort, mapBy } from '@ember/object/computed';
import AutoSave from '../mixins/auto-save-model';
import IndicateSaveing from '../mixins/indicate-saving-model';
import {
  task
} from 'ember-concurrency';
import {
  inject
} from '@ember/service';

export default DS.Model.extend(AutoSave, IndicateSaveing, {
  automatedAllocation: inject(),
  teilnehmer: DS.belongsTo('teilnehmer', { async: true, inverse: 'termAnmeldungen' }),
  term: DS.belongsTo('term', { async: true, inverse: 'termAnmeldungen' }),
  anmeldungen: DS.hasMany('anmeldung', { async: true, inverse: 'termAnmeldung' }),
  _nachname: DS.attr('string'),
  _vorname: DS.attr('string'),
  _email: DS.attr('string'),
  _maxNOfEvents: DS.attr('number'),
  _random: DS.attr('number'),
  notiz: DS.attr('string'),
  optimizerTempGroup: DS.attr('string'),
  todos: DS.hasMany('todos', { async: true, inverse: 'teilnehmer' }),
  events: mapBy('anmeldungen', 'event'),
  eventIDs: mapBy('events', 'id'),
  sortedAnmeldungen: sort('anmeldungen', 'automatedAllocation.prioSort'),
  random: computed('_random', 'isLoaded', function(){
    if(this.get('isLoaded')){
      if(!this.get('_random')){
        this.set('_random', Math.random())
      }
      return this.get('_random')
    }
    return 0
  }),
  lengthAnmeldungen: computed('lengthIncludedAnmeldungen', 'lengthExcludedAnmeldungenWithoutWish', 'lengthToBeDetermindAnmeldungen', function(){
    return this.get('lengthIncludedAnmeldungen')+this.get('lengthExcludedAnmeldungenWithoutWish')+this.get('lengthToBeDetermindAnmeldungen');
  }),
  includedAnmeldungen: filterBy('anmeldungen', 'status', 'included'),
  lengthIncludedAnmeldungen: alias('includedAnmeldungen.length'),
  excludedAnmeldungen: computed('anmeldungen.@each.{status,absageGrund}', function(){
      return this.get('anmeldungen').filterBy('status', 'excluded');
  }),
  lengthExcludedAnmeldungen: alias('excludedAnmeldungen.length'),
  excludedAnmeldungenWithoutWish: computed('excludedAnmeldungen.@each.absageGrund', function(){
    return this.get('excludedAnmeldungen').rejectBy('absageGrund', 'wish').rejectBy('absageGrund', 'wish-maxnofevents');
  }),
  lengthExcludedAnmeldungenWithoutWish: alias('excludedAnmeldungenWithoutWish.length'),
  toBeDetermindAnmeldungen: filterBy('anmeldungen', 'status', 'toBeDetermind'),
  lengthToBeDetermindAnmeldungen: alias('toBeDetermindAnmeldungen.length'),
  noNiveauTodoAnmeldungen: filterBy('anmeldungen', 'isNiveauTodo', false),
  niveauTodoAnmeldungen: filterBy('anmeldungen', 'isNiveauTodo', true),
  openNiveauTodoAnmeldungen: filterBy('anmeldungen', 'isOpenNiveauTodo', true),
  openNiveauTodoAnmeldungenLength: alias('openNiveauTodoAnmeldungen.length'),
  hasNiveauTodo: computed('anmeldungen.@each.isNiveauTodo', function(){
    return this.get('anmeldungen').any(a => a.isNiveauTodo)
  }),
  hasOpenNiveauTodo: computed('anmeldungen.@each.isOpenNiveauTodo', function(){
    return this.get('anmeldungen').any(a => a.isOpenNiveauTodo)
  }),
  includedAnmeldungWithHighestPriority: computed('includedAnmeldungen.@each.{runde,userPriority}', function(){
    return this.get('includedAnmeldungen').reduce((previousValue,item) => {
      if(!previousValue || item.get('runde')<previousValue.get('runde') || (item.get('runde')===previousValue.get('runde') && item.get('userPriority')<previousValue.get('userPriority'))){
        return item
      }else{
        return previousValue
      }
    },false)
  }),
  maxNOfEvents: computed('_maxNOfEvents', 'anmeldungen.@each.{_maxNOfEvents,anmeldezeitpunkt}', {
    get() {
        if(this.get('_maxNOfEvents') !== null){
            if(this.get('_maxNOfEvents') === -1){
              return '';
            }else{
              return this.get('_maxNOfEvents');
            }
        }else{
            return this.get('anmeldungen').sortBy('anmeldezeitpunkt').reduce(function(previousValue, item){
                if(item.get('_maxNOfEvents')){
                    return item.get('_maxNOfEvents');
                }else{
                    return previousValue;
                }
            }, null)
        }
    },
    set(key, value) {
      if(value === ''){
        this.set('_maxNOfEvents', -1);
      }else{
        this.set('_maxNOfEvents', value);
      }
      return value;
    }
  }),
  customMaxNOfEvents: computed('_maxNOfEvents', function(){
    return this.get('_maxNOfEvents') !== null
  }),
  clearCustomMaxNOfEvents: task(function* () {
    this.set('_maxNOfEvents', '');
    yield true
  }),
  gender: computed('teilnehmer.gender', function(){
    return this.get('teilnehmer.gender') ? this.get('teilnehmer.gender') : '*';
  }),
  vorname: computed('teilnehmer.vorname', '_vorname', function(){
    return this.get('teilnehmer.vorname') ? this.get('teilnehmer.vorname') : this.get('_vorname');
  }),
  nachname: computed('teilnehmer.nachname', '_nachname', function(){
    return this.get('teilnehmer.nachname') ? this.get('teilnehmer.nachname') : this.get('_nachname');
  }),
  email: computed('teilnehmer.email', '_email', function(){
    return this.get('teilnehmer.email') ? this.get('teilnehmer.email') : this.get('_email');
  }),
  emailHref: computed('email', function(){
    return 'mailto:'+this.get('email')
  }),
  isMitglied: computed('teilnehmer.isMitglied', function(){
    if(this.get('teilnehmer.isMitglied')){
      return this.get('teilnehmer.isMitglied')
    }else{
      return false
    }
  }),
  aktivenStatus: computed('teilnehmer.aktivenStatus', function(){
    if(this.get('teilnehmer.aktivenStatus')){
      return this.get('teilnehmer.aktivenStatus')
    }else{
      return 0
    }
  }),
  aktiv: computed('teilnehmer.aktiv', function(){
    return this.get('teilnehmer.aktiv');
  }),
  activityLevel: alias('teilnehmer.activityLevel'),
  latestNiveau: computed('teilnehmer.latestNiveau', function(){
    return this.get('teilnehmer.latestNiveau') ? this.get('teilnehmer.latestNiveau') : false; //TODO: false okay?
  }),
  latestNiveauEinschaetzer: computed('teilnehmer.latestNiveauEinschaetzer', function(){
    return this.get('teilnehmer.latestNiveauEinschaetzer') ? this.get('teilnehmer.latestNiveauEinschaetzer') : false; //TODO: false okay?
  }),
  niveaueinschaetzungen: computed('teilnehmer.niveaueinschaetzungen', function(){
    return this.get('teilnehmer.niveaueinschaetzungen') ? this.get('teilnehmer.niveaueinschaetzungen') : [];
  }),
  absageGrundString: computed('excludedAnmeldungen.@each.absageGrund', function(){ //TODO: besserer Name?
      return this.get('excludedAnmeldungen').sortBy('absageGrund').reduce(function(previousValue, item){
        return previousValue +"-"+ item.get('absageGrund');
      }, "")
  }),
  withoutEvent: computed('lengthIncludedAnmeldungen', 'lengthToBeDetermindAnmeldungen', 'lengthExcludedAnmeldungenWithoutWish', function(){
    return this.get('lengthIncludedAnmeldungen') == 0 && this.get('lengthToBeDetermindAnmeldungen') == 0 && this.get('lengthExcludedAnmeldungenWithoutWish') != 0;
  }),
  isPrioForNextTerm: computed('term.id', 'teilnehmer.priosForNextTerm.@each.id', {
    get() {
      if(this.get('teilnehmer.priosForNextTerm')){
        return this.get('teilnehmer.priosForNextTerm').isAny('id', this.get('term.id'));
      }else{
        return false
      }
    },
    set(key, value) {
        if(value){
            this.get('teilnehmer.priosForNextTerm').pushObject(this.get('term'));//TODO: on Term
        }else{
            this.get('teilnehmer.priosForNextTerm').removeObject(this.get('term'));//TODO: on Term
        }
        return value;
    }
  }),
  hasMaxNOfEvents: computed('maxNOfEvents', 'anmeldungen.@each._status', function(){
    if(this.get('maxNOfEvents')){
      let length = this.get('anmeldungen').filterBy('_status', 'included').length;
      return (this.get('maxNOfEvents') <= length)
    }else{
      return false;
    }
  }),
  hasAtLeastOneEvent: computed('lengthIncludedAnmeldungen', function(){
    return this.get('lengthIncludedAnmeldungen') > 0;
  }),
  weightedAnmeldung: computed('maxNOfEvents','lengthAnmeldungen', function(){ //TODO: Besserer Name?
    if(this.get('maxNOfEvents')){
      return Math.min(1, this.get('maxNOfEvents')/this.get('lengthAnmeldungen'));
    }else{
      return 1;
    }
  }),
  weightedAnmeldungNotIncluded: computed('maxNOfEvents','lengthAnmeldungen','lengthIncludedAnmeldungen', function(){//TODO: Besserer Name?
    if(this.get('maxNOfEvents')){
      return Math.min(1, (this.get('maxNOfEvents')-this.get('lengthIncludedAnmeldungen'))/(this.get('lengthAnmeldungen')-this.get('lengthIncludedAnmeldungen')));
    }else{
      return 1;
    }
  }),
  weightedAnmeldungOneEventPerTeilnehmer: computed('lengthAnmeldungen', function(){//TODO: Besserer Name?
    if(this.get('lengthAnmeldungen') > 0){
      return 1/this.get('lengthAnmeldungen');
    }else{
      return 0;
    }
  }),
  href: computed('teilnehmer', function(){
    if(!this.belongsTo('teilnehmer').id()) return '#'
    return 'https://www.vereinonline.org/BeachL_eV/?action=members_show&id='+this.belongsTo('teilnehmer').id()
  }),
  todosWithAnmeldungen: computed('todos.[]', 'anmeldungen.@each.todos', function(){
    let out = [];
    out = out.pushObjects(this.get('todos').toArray())
    this.get('anmeldungen').forEach(i => {
        out = out.pushObjects(i.get('todos').toArray())
    })
    return out
  }),
  todoStatus: computed('todosWithAnmeldungen.@each.status', function(){
      if(this.get('todosWithAnmeldungen').isAny('status', 'Todo')){
          return 'Todo'
      }else if(this.get('todosWithAnmeldungen').isAny('status', 'Waiting')){
          return 'Waiting'
      }else if(this.get('todosWithAnmeldungen').isAny('status', 'Done')){
          return 'Done'
      }else{
          return 'None'
      }
  }),
  todoStatusTodoOrWaiting: computed('todoStatus', function(){
    return this.get('todoStatus') === 'Todo' || this.get('todoStatus') === 'Waiting'
  }),
  termID: alias('term.id'),
  termName: alias('term.name'),
  lastTermAnmeldung: computed('teilnehmer.termAnmeldungen.@each.termID', 'term.lastTerm.id', function(){
    const lastTerm = this.get('term.lastTerm')
    return this.get('teilnehmer.termAnmeldungen').find(a => a.get('term.id') === lastTerm.get('id'))
  }),
  openNiveauEinschaetzungen: filterBy('includedAnmeldungen', 'hasNiveauEinschaetzung', false),
  openNiveauEinschaetzungenFromLastTerm: alias('lastTermAnmeldung.openNiveauEinschaetzungen'),
  eventAtTheSameDay: computed('includedAnmeldungen.@each.datum', function(){
    return this.get('includedAnmeldungen').uniqBy((a) => moment(a.get('datum')).format("dd")).get('length') < this.get('includedAnmeldungen.length')
  })
});
