import Controller from '@ember/controller';
import {
  task
} from 'ember-concurrency';
import {
  inject
} from '@ember/service';

export default Controller.extend({
  store: inject(),
  delete: task(function* () {
    const term = yield this.get('model.term')
    const step = yield this.get('model.step')
    step.destroyRecord()
    term.save()
    this.transitionToRoute('term.term.process-steps', term.get('id'))
  }),
  duplicate: task(function* () {
    const term = yield this.get('model.term')
    const s = yield this.get('model.step')
    const store = this.get('store')
    const ns = yield store.createRecord('process-step', {
        term: s.term,
        name: s.name,
        text: s.text,
        type: s.type,
        datesRelativeTo: s.datesRelativeTo,
        url: s.url,
        position: s.position,
        start: s.start,
        end: s.end,
        reminder: s.reminder
    }).save()
    yield term.save()
    this.transitionToRoute('term.term.process-steps.step', ns.get('id'))
  }),
});
