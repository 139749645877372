import DS from 'ember-data';
import AutoSave from '../mixins/auto-save-model';
import IndicateSaveing from '../mixins/indicate-saving-model';
import {
  computed
} from '@ember/object';

export default DS.Model.extend(AutoSave, IndicateSaveing, {
  name: DS.attr('string'),
  zugelassene_einschaetzungen: DS.attr('string'),
  zugelasseneEinschaetzungen: computed('zugelassene_einschaetzungen', function(){
    if(!this.get('zugelassene_einschaetzungen') || this.get('zugelassene_einschaetzungen') === '') return false
    return this.get('zugelassene_einschaetzungen').split(';')
  })
});
