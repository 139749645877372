import FirebaseAdapter from 'emberfire/adapters/firebase';
import { isNone } from '@ember/utils';
import { allSettled } from 'rsvp';
import { A } from '@ember/array';
import Ember from 'ember';
const { assign } = Ember;

export default FirebaseAdapter.extend({

  /**
   * Called by the store when a record is created/updated via the `save`
   * method on a model record instance.
   *
   * The `updateRecord` method serializes the record and performs an `update()`
   * at the the Firebase location and a `.set()` at any relationship locations
   * The method will return a promise which will be resolved when the data and
   * any relationships have been successfully saved to Firebase.
   *
   * We take an optional record reference, in order for this method to be usable
   * for saving nested records as well.
   */
  updateRecord(store, typeClass, snapshot) {
    var recordRef = this._getAbsoluteRef(snapshot.record);
    var recordCache = this._getRecordCache(typeClass, snapshot.id);
    var pathPieces = recordRef.path.toString().split('/');
    var lastPiece = pathPieces[pathPieces.length - 1];
    var serializedRecord = snapshot.serialize({
      includeId: (lastPiece !== snapshot.id) // record has no firebase `key` in path
    });
    const serializer = store.serializerFor(typeClass.modelName);

    return new Promise((resolve, reject) => {
      var relationshipsToSave = [];
      // first we remove all relationships data from the serialized record, we backup the
      // removed data so that we can save it at a later stage.
      snapshot.record.eachRelationship((key, relationship) => {
        const relationshipKey = serializer.keyForRelationship(key);
        const data = serializedRecord[relationshipKey];
        const isEmbedded = this.isRelationshipEmbedded(store, typeClass.modelName, relationship);
        const hasMany = relationship.kind === 'hasMany';
        if (hasMany || isEmbedded) {
          if (!isNone(data)) {
            relationshipsToSave.push({
              data: data,
              relationship: relationship,
              isEmbedded: isEmbedded,
              hasMany: hasMany
            });
          }
          delete serializedRecord[relationshipKey];
        }
      });
      var reportError = (errors) => {
        var error = new Error(`Some errors were encountered while saving ${typeClass} ${snapshot.id}`);
        error.errors = errors;
        reject(error);
      };
      this._updateRecord(recordRef, serializedRecord).then(() => {
        return recordRef.once('value');
      }).catch((e) => {
        console.log(e); //TODO: where error reporting
        reportError([e]);
      }).then((onlineSnapshot) => {
        this._handleChildValue(store, typeClass, onlineSnapshot);
        // and now we construct the list of promise to save relationships.
        var savedRelationships = relationshipsToSave.map((relationshipToSave) => {
          const data = relationshipToSave.data;
          const relationship = relationshipToSave.relationship;
          if (relationshipToSave.hasMany) {
            return this._saveHasManyRelationship(store, typeClass, relationship, data, recordRef, recordCache);
          } else {
            // embedded belongsTo, we need to fill in the informations.
            if (relationshipToSave.isEmbedded) {
              return this._saveEmbeddedBelongsToRecord(store, typeClass, relationship, data, recordRef);
            }
          }
        });
        return allSettled(savedRelationships);
      }).then((results) => {
        var rejected = A(results).filterBy('state', 'rejected');
        if (rejected.length !== 0) {
          reportError(rejected.mapBy('reason').toArray());
        } else {
          resolve();
        }
      });
    }, `DS: FirebaseAdapter#updateRecord ${typeClass} to ${recordRef.toString()}`);
  },

  /**
   * Returns the parent record and relationship where any embedding is detected
   *
   * @param  {DS.InternalModel} internalModel
   * @return {Object}
   */
  getFirstEmbeddingParent(internalModel) {
    let relationships = assign(
      {},
      internalModel._implicitRelationships,
      internalModel._relationships.initializedRelationships
    );

    let embeddingParentRel;
    let relationshipKeys = Object.keys(relationships);

    for (let i = 0; i < relationshipKeys.length; i++) {
      let rel = relationships[relationshipKeys[i]];
      let members = rel.members.toArray();
      let parent = members[0];

      if (!parent || !rel.inverseKey) {
        continue;
      }

      let parentRel = parent._relationships.get(rel.inverseKey);
      if (this.isRelationshipEmbedded(this.store, parent.modelName, parentRel.relationshipMeta)) {
        embeddingParentRel = rel;
        break;
      }
    }

    if (embeddingParentRel) {
      var parent = embeddingParentRel.members.toArray()[0];
      var parentKey = embeddingParentRel.inverseKey;
      var parentRel = parent._relationships.get(parentKey).relationshipMeta;
      return { record: parent, relationship: parentRel };
    }
  },
});
