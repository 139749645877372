import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';

export default Controller.extend({
    automatedAllocation: service(),
    vereinonlineRequests: service(),
    actions:{
      extendedSortKey(key){
        if(key == 'kurs:desc'){
          return ['lengthIncludedAnmeldungen:desc', 'lengthToBeDetermindAnmeldungen:desc'];
        }else if(key == 'kurs:asc'){
          return ['lengthIncludedAnmeldungen:asc', 'lengthToBeDetermindAnmeldungen:asc'];
        }else if(key == 'statusAndPrio:desc'){
          return this.get('automatedAllocation.prioSort')
        }else if(key == 'statusAndPrio:asc'){
          return this.get('automatedAllocation.prioSort')
        }else{
          return key;
        }
      }
    }
});
