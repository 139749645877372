import Route from '../../../-base';
import {
    task,
    all
  } from 'ember-concurrency';

export default Route.extend({
    model(){
        const model = this.modelFor('term.term.term-anmeldung');
        const teilnehmer = this.get('loadTeilnehmer').perform(model.termAnmeldung);
        const termAnmeldungen = this.get('loadTermAnmeldungen').perform(teilnehmer);
        const anmeldungen = this.get('loadAnmeldungen').perform(termAnmeldungen);
        const events = this.get('loadAnmeldungen').perform(anmeldungen);
        const terms = this.get('loadTerms').perform(termAnmeldungen)
        return {
            term: model.term,
            activityLevel: model.activityLevel,
            termAnmeldung: model.termAnmeldung,
            allLoaded: this.get('allLoaded').perform(events, terms)
        }
    },

    loadTeilnehmer: task(function* (termAnmeldung) {
        termAnmeldung = yield termAnmeldung;
        return yield termAnmeldung.get('teilnehmer');
    }),

    loadTermAnmeldungen: task(function* (teilnehmer) {
        teilnehmer = yield teilnehmer;
        return yield teilnehmer.get('termAnmeldungen');
    }),

    loadAnmeldungen: task(function* (termAnmeldungen) {
        termAnmeldungen = yield termAnmeldungen;
        yield all(termAnmeldungen.map(item => item.get('anmeldungen')));
        return termAnmeldungen.reduce((previousValue, item) => {
            if(item.get('anmeldungen')){previousValue.pushObjects(item.get('anmeldungen').map(item => item))}
            return previousValue
        }, []);
    }),

    loadEvents: task(function* (anmeldungen) {
        anmeldungen = yield anmeldungen;
        yield all(anmeldungen.map(item => item.get('event')));
    }),

    loadTerms: task(function* (termAnmeldungen) {
        termAnmeldungen = yield termAnmeldungen;
        yield all(termAnmeldungen.map(item => item.get('term')));
    }),

    allLoaded: task(function* (events, terms) {
        yield events;
        yield terms;
        return true;
      }),
});