import DS from 'ember-data';
import AutoSave from '../mixins/auto-save-model';
import IndicateSaveing from '../mixins/indicate-saving-model';
import {
  computed
} from '@ember/object';
import moment from 'moment';
import {
  task
} from 'ember-concurrency';

function createDateComputed(attr){
  return computed(attr, {
    get() {
      if(moment(this.get(attr)).valueOf() === 0) return ''
      return moment(this.get(attr)).format('YYYY-MM-DD');
    },
    set(key, value) {
      const m = moment(value, "YYYY-MM-DD")
      if(m.isValid()){
        this.set(attr, m.toDate())
      }else{
        this.set(attr, moment(0).toDate())
      }
      return value;
    }
  })
}

function createRelativeWeeksComputed(attr){
  return computed(attr, 'datesRelativeTo', 'term.{von,bis}', {
    get() {
      if(moment(this.get(attr)).valueOf() === 0) return ''
      if(this.get('datesRelativeTo') === "end"){
        if(moment(this.get('term.bis')).valueOf() === 0) return ''
        return moment(this.get(attr)).diff(moment(this.get('term.bis')), 'weeks')
      }else{
        if(moment(this.get('term.von')).valueOf() === 0) return ''
        return moment(this.get(attr)).diff(moment(this.get('term.von')), 'weeks')
      }
    },
    set(key, value) {
      if(value === ''){
        this.set(attr, moment(0).toDate())
      }else{
        if(this.get('datesRelativeTo') === "end"){
          if(moment(this.get('term.bis')).valueOf() === 0){
            this.set(attr, moment(0).toDate())
          }else{
            this.set(attr, moment(this.get('term.bis')).add(value, 'weeks').toDate())
          }
        }else{
          if(moment(this.get('term.von')).valueOf() === 0){
            this.set(attr, moment(0).toDate())
          }else{
            this.set(attr, moment(this.get('term.von')).add(value, 'weeks').toDate())
          }
        }
      }
      return value;
    }
  })
}

export default DS.Model.extend(AutoSave, IndicateSaveing, {
  term: DS.belongsTo('term', { async: true, inverse: 'processSteps' }),
  name: DS.attr('string'),
  text: DS.attr('string'),
  textSnippet: DS.attr('string'),
  type: DS.attr('string', { defaultValue: 'task' }),
  status: DS.attr('string', { defaultValue: 'Todo' }),
  start: DS.attr('fire-date'),
  end: DS.attr('fire-date'),
  date: DS.attr('fire-date'),
  dateRequiered: DS.attr('boolean'),
  reminder: DS.attr('fire-date'),
  datesRelativeTo: DS.attr('string', { defaultValue: 'start' }),
  url: DS.attr('string'),
  instructionUrl: DS.attr('string'),
  position: DS.attr('number'),
  startString: createDateComputed('start'),
  endString: createDateComputed('end'),
  dateString: createDateComputed('date'),
  reminderString: createDateComputed('reminder'),
  startRelative: createRelativeWeeksComputed('start'),
  endRelative: createRelativeWeeksComputed('end'),
  dateRelative: createRelativeWeeksComputed('date'),
  reminderRelative: createRelativeWeeksComputed('reminder'),
  replacedUrl: computed('url', 'term.id', function(){
    
    let sentence = this.get('url')
    let data = {term: this.get('term.id')}
    let placeholders = sentence.match(/\$(.*?)\$/g)
    if(placeholders){
      placeholders.forEach(function(placeholder){
        const phText = placeholder.substring(1,placeholder.length - 1);
        if(data[phText]){
          sentence = sentence.replace(placeholder,data[phText])
        }
      })
    }

    return sentence
  }),
  rearange: task(function* (position) {
    const steps = yield this.get('term.processStepsOrderd')
    if(position === 0){
      this.set('position', steps.get('firstObject.position')-100)
    }else if(position === steps.get('length')){
      this.set('position', steps.get('lastObject.position')+100)
    }else{
      this.set('position', (steps.objectAt(position-1).get('position')+steps.objectAt(position).get('position'))/2)
    }
  }),
});
