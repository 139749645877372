import Component from '@ember/component';

export default Component.extend({
    actions: {
        change() {
            if(this.get('status') === "Todo"){
                this.get('onChange')("Waiting");
            }else if(this.get('status') === "Waiting"){
                this.get('onChange')("Done");
            }else if(this.get('status') === "Done"){
                this.get('onChange')("Todo");
            }
        },
    }
});