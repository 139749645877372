import DS from 'ember-data';
import AutoSave from '../mixins/auto-save-model';
import IndicateSaveing from '../mixins/indicate-saving-model';
import {
    computed
  } from '@ember/object';

export default DS.Model.extend(AutoSave, IndicateSaveing, {
    anrede: DS.attr('string'),
    nachname: DS.attr('string'),
    vorname: DS.attr('string'),
    rolle: DS.attr('string'),
    email: DS.attr('string'),
    termAnmeldungen: DS.hasMany('term-anmeldung', { async: true, inverse: 'teilnehmer' }),
    niveaueinschaetzungen: DS.hasMany('niveaueinschaetzung', { async: true, inverse: 'teilnehmer' }),
    latestNiveau: DS.attr('string'),
    latestNiveauDatum: DS.attr('fire-date'),
    latestNiveauEinschaetzer: DS.attr('string'),
    priosForNextTerm: DS.hasMany('term', { async: true, inverse: null }),
    eventsAlsTrainer: DS.hasMany('event', { async: true, inverse: 'trainer' }),
    activityLevel: DS.belongsTo('activity-level', { async: true }),
    activityLevelPriority: DS.attr('number'),
    isMitglied: computed('rolle', function(){
        if(this.get('rolle')){
            return this.get('rolle').includes("Mitglied");
        }else{
            return false;
        }
    }),
    aktivenStatus: computed('isMitglied', 'activityLevelPriority', function(){
        if(this.get('isMitglied')){
            if(this.get('activityLevelPriority')){
                return this.get('activityLevelPriority')
            }else{
                return 0
            }
        }
    }),
    aktiv: computed('aktivenStatus', function(){
        return this.get('aktivenStatus') > 0;
    }),
    gender: computed('anrede', function(){
        if(this.get('anrede') === 'Frau'){
            return 'Frau'
        }else if(this.get('anrede') === 'Herr'){
            return 'Mann'
        }else{
            return '*';
        }
    }),
    name: computed('vorname', 'nachname', function(){
        return this.get('vorname')+' '+this.get('nachname')
    }),
    admin: computed('rolle', function(){
        return this.get('rolle').includes("App-Einteilung-Admin")
    })

});
