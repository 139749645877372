import Route from '../../-base';
import {
  task,
} from 'ember-concurrency';
import {
  inject
} from '@ember/service';

export default Route.extend({
    router: inject(),
    model(params) {
      const parentModel = this.modelFor('term.term');
      const termAnmeldung = this.get('findTermAnmeldung').perform(params.term_anmeldung_id);
      return {
        term: parentModel.term,
        activityLevel: parentModel.activityLevel,
        termAnmeldung: termAnmeldung,
        allLoadedForStatus: parentModel.allLoadedForStatus,
        vorlagen: parentModel.vorlagen
      };
    },
    findTermAnmeldung: task(function* (term_anmeldung_id) {
        return yield this.get('store').findRecord('term-anmeldung', term_anmeldung_id);
    }),
    setupController(controller, model, transition) {
      model.backurl = document.location.pathname === transition.intent.url ? false : document.location.pathname+window.location.search+window.location.hash
      this._super(controller, model);
    }
})

