import Route from './-base';
import {
  task
} from 'ember-concurrency';
import {
  inject as service
} from '@ember/service';

export default Route.extend({
  session: service(),
  auth: service(),
  paperToaster: service(),
  moment: service(),
  apiFunctions: service(),
  isFirstRun: true,
  beforeModel: function () {
    return this.get('start').perform();
  },
  model() {

  },
  //TODO: move to auth service
  start: task(function* () {
    yield this.get('session').fetch().catch(function () {});
    this.get('moment').setLocale('de');
  }),
  actions:{
    refresh(){
      this.refresh();
    }
  }
});
