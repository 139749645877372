import Route from '../-base';
import {
  task
} from 'ember-concurrency';

export default Route.extend({
    model() {
        return {
          terms: this.get('findTerms').perform() 
        };
    },
    findTerms: task(function* () {
        return yield this.get('store').findAll('term');
    }),
});