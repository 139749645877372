import Service from '@ember/service';
import {
    task
  } from 'ember-concurrency';
import { defer } from 'rsvp';

export default Service.extend({
    errorOccured: task({
        theError: null,
        *perform(error) {
            this.set('theError', error);
            yield defer().promise;
        },
    }).drop(),
});
